<template>
  <div>
    <AppGrid
      :grid-id="assessmentRatesGridId"
      data-endpoint="assessment_rates/list"
      :columns="assessmentRatesColumns"
      default-sort-attr="created_at"
      :default-sort-dir="-1"
      :selectable="true"
      :expandable="false"
      selection-data-key="prim_uid"
      navigation-data-key="prim_uid"
      :server-params="serverParams"
      :menus="assessmentRatesMenus"
      :show-select-col="false"
      :show-filter="false"
      :show-view-menu="false"
      :state-ful="false"
      :show-create-record="false"
      :auto-load="false"
      @menu-clicked="handleAssessmentRatesMenuClick"
      @col-click="handleAssessmentRatesColClick"
    />

    <Dialog
      :modal="true"
      :visible.sync="assessmentRatesModalEdit"
      :contentStyle="{ flex: '1' }"
      @hide="resetAssessmentRateModal"
    >
      <template #header>
        <h3 class="text-xl font-bold">
          {{ $t('settings.taxCategories.assessment_rates.assessment_rate') }}
        </h3>
      </template>

      <div>
        <div class="flex flex-col w-full justify-start">
          <div class="flex flex-col w-full">
            <InputSelect
              class="mt-2 w-full"
              v-model="assessmentRateModalData.federalStateUid"
              :selected-value="assessmentRateModalData.federalStateUid"
              :errorMessage="$t('general.errors.required')"
              :label="$t('settings.taxCategories.assessment_rates.modal.federal_state')"
              :isRequired=true
              :options="stateList"
              :placeholder="$t('general.please_select')"
              @on-item-selected="fetchCommunityList"
            />
          </div>

          <div class="flex flex-col w-full">
            <InputSelect
              class="mt-5 w-full"
              v-model="assessmentRateModalData.community"
              :selected-value="assessmentRateModalData.community"
              :options="communityList"
              dataKey="community_key"
              :loading="loadingCommunity"
              :errorMessage="$t('general.errors.required')"
              :isRequired=true
              :label="$t('settings.taxCategories.assessment_rates.modal.community_select')"
              :filterable="true"
              :placeholder="$t('general.please_select')"
            />
          </div>

          <div
            v-if="hasSelectedFederalState"
            class="flex flex-col w-full"
          >
            <div class="flex pt-4">
              <p class="text-lg text-muted_black">
                {{$t('settings.taxCategories.assessment_rates.modal.rates_by_property_type')}}
              </p>

              <VDropdown
                :showTriggers="['click','hover']"
                :popperTriggers="['hover','click']"
                :hideTriggers="['click']"
              >
                <img class="ml-1 size-5" :src="getAssetPath('help_green.svg')"/>

                <template #popper>
                  <div class="tooltip-content">
                    <p
                      class="space-y-2 mb-1 popover-text"
                      v-html="$t('settings.taxCategories.assessment_rates.modal.rates_by_property_type_tooltip')"
                    />
                  </div>
                </template>
              </VDropdown>
            </div>

            <div
              v-if="assessmentRateShow.assessmentRateForLandAndForestry"
              class="flex flex-col w-full"
            >
              <InputText
                v-model="assessmentRateModalData.landAndForestryAssessmentRate"
                :isRequired="false"
                :label="$t('settings.taxCategories.assessment_rates.modal.land_and_forestry_assessment_rate')"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :maxLength="5"
                placeholder=""
                class="mt-5 mb-1 w-full"
              />
            </div>

            <div
              v-if="assessmentRateShow.assessmentRateForResidential"
              class="flex flex-col w-full"
            >
              <InputText
                v-model="assessmentRateModalData.residentialAssessmentRate"
                :isRequired="false"
                :label="$t('settings.taxCategories.assessment_rates.modal.residential_assessment_rate')"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :maxLength="5"
                placeholder=""
                class="mt-5 mb-1 w-full"
              />
            </div>

            <div
              v-if="assessmentRateShow.assessmentRateForNonResidential"
              class="flex flex-col w-full"
            >
              <InputText
                v-model="assessmentRateModalData.nonResidentialAssessmentRate"
                :isRequired="false"
                :label="$t('settings.taxCategories.assessment_rates.modal.non_residential_assessment_rate')"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :maxLength="5"
                placeholder=""
                class="mt-5 mb-1 w-full"
              />
            </div>

            <div
              v-if="assessmentRateShow.assessmentRateBuilt"
              class="flex flex-col w-full"
            >
                <InputText
                  v-model="assessmentRateModalData.builtAssessmentRate"
                  :isRequired="false"
                  :label="$t('settings.taxCategories.assessment_rates.modal.built_assessment_rate')"
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="5"
                  placeholder=""
                  class="mt-5 mb-1 w-full"
                />
            </div>

            <div
              v-if="assessmentRateShow.assessmentRateForUndeveloped"
              class="flex flex-col w-full"
            >
              <InputText
                v-model="assessmentRateModalData.undevelopedAssessmentRate"
                :isRequired="false"
                :label="$t('settings.taxCategories.assessment_rates.modal.undeveloped_assessment_rate')"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :maxLength="5"
                placeholder=""
                class="mt-5 mb-1 w-full"
              />
            </div>

            <div
              v-if="assessmentRateShow.assessmentRateBuiltUnbuilt"
              class="flex flex-col w-full"
            >
              <InputText
                v-model="assessmentRateModalData.builtAndUnbuiltAssessmentRate"
                :isRequired="false"
                :label="$t('settings.taxCategories.assessment_rates.modal.built_unbuilt_assessment_rate')"
                :isOnlyNumber="true"
                :isOnlyPositive="true"
                :maxLength="5"
                placeholder=""
                class="mt-5 mb-1 w-full"
              />
            </div>
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <div class="flex flex-row-reverse gap-4 m-4">
          <Button
            :text="$t('buttons.cancel')"
            :secondary="true"
            @click="assessmentRatesModalEdit = false"
          />
          <Button
            :text="$t('buttons.save')"
            :disabled="!isValidForSaving"
            @click="saveAssessmentRates"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';

import Dialog from 'primevue/dialog';

import AppGrid from '@/components/AppGrid';

export default {
  name: 'AssessmentRateTable',
  components: {
    AppGrid,
    Dialog
  },
  props: {
  },
  data() {
    return {
      assessmentRatesGridId: 'assessment_rates',
      assessmentRatesColumns: [
        {
          field: 'federal_state_name',
          header: this.$t('settings.taxCategories.assessment_rates.columns.federal_state'),
          isChecked: true,
          elType: 'state_select',
          filterableSelect: true,
          clickable: true
        },
        {
          field: 'community',
          header: this.$t('settings.taxCategories.assessment_rates.columns.community'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
        {
          field: 'land_and_forestry_assessment_rate',
          header: this.$t('settings.taxCategories.assessment_rates.columns.land_and_forestry_assessment_rate'),
          isChecked: true,
          elType: 'text',
          width: 190,
          clickable: true
        },
        {
          field: 'residential_assessment_rate',
          header: this.$t('settings.taxCategories.assessment_rates.columns.residential_assessment_rate'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
        {
          field: 'non_residential_assessment_rate',
          header: this.$t('settings.taxCategories.assessment_rates.columns.non_residential_assessment_rate'),
          isChecked: true,
          elType: 'text',
          width: 170,
          clickable: true
        },
        {
          field: 'undeveloped_assessment_rate',
          header: this.$t('settings.taxCategories.assessment_rates.columns.undeveloped_assessment_rate'),
          isChecked: true,
          elType: 'text',
          clickable: true
        },
      ],
      assessmentRatesMenus: [
        {
          id: 'cm.add_assessment_rate',
          icon: this.getAssetPath('add_declaration_green.svg'),
          title: this.$t('settings.taxCategories.assessment_rates.menus.add')
        },
        {
          id: 'cm.edit_assessment_rate',
          icon: this.getAssetPath('edit_green.svg'),
          icon_disabled: this.getAssetPath('edit_gray.svg'),
          title: this.$t('settings.taxCategories.assessment_rates.menus.edit'),
          disable_on_multi: true,
          enable_on_select: true,
          disabled: true
        },
        {
          id: 'cm.delete_assessment_rate',
          icon: this.getAssetPath('delete_green.svg'),
          icon_disabled: this.getAssetPath('delete_gray.svg'),
          title: this.$t('settings.taxCategories.assessment_rates.menus.delete'),
          action_endpoint: 'assessment_rates/delete',
          action_confirmation: {
            show: true
          },
          callback: (response) => {
            this.deleteCallback(response)
          },
          disable_on_multi: false,
          enable_on_select: true,
          disabled: true
        }
      ],
      serverParams: {
        parentType: '',
        parentId: 0,
        gridId: this.assessmentRatesGridId
      },

      stateList: [],

      loadingCommunity: false,
      communityList: [],

      assessmentRatesModalEdit: false,
      assessmentRateModalData: this.getDefaultAssessmentRateModalData(),
    }
  },
  beforeMount() {
    this.$api.get_state_list().then((list) => {
      this.stateList = list;
    });
  },
  watch: {
    'assessmentRateModalData.federalStateUid': {
      handler: function (newValue, oldValue) {
        if (parseInt(newValue) === 0) {
          this.communityList = [];
        }

        if (parseInt(oldValue) !== parseInt(newValue) && parseInt(oldValue) !== 0) {
          this.assessmentRateModalData = {
            ...this.assessmentRateModalData,
            ...this.getDefaultAssessmentRates(),
          }
        }
      }
    }
  },
  computed: {
    hasSelectedFederalState() {
      return parseInt(this.assessmentRateModalData.federalStateUid) > 0;
    },
    isValidForSaving() {
      return (parseInt(this.assessmentRateModalData.federalStateUid) > 0 && this.assessmentRateModalData.community !== '');
    },
    assessmentRateShow() {
      let federalStateUid = parseInt(this.assessmentRateModalData.federalStateUid);

      let assessmentRateShow = {
        assessmentRateForLandAndForestry: false,
        assessmentRateForResidential: false,
        assessmentRateForNonResidential: false,
        assessmentRateForUndeveloped: false,
        assessmentRateBuilt: false,
        assessmentRateBuiltUnbuilt: false
      };

      switch(federalStateUid) {
        case 1:
        case 2:
          assessmentRateShow.assessmentRateForLandAndForestry = true;
          assessmentRateShow.assessmentRateBuiltUnbuilt = true;
          break;

        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 12:
        case 14:
        case 16:
          assessmentRateShow.assessmentRateForLandAndForestry = true;
          assessmentRateShow.assessmentRateBuilt = true;
          assessmentRateShow.assessmentRateForUndeveloped = true;
          break;

        case 10:
        case 11:
        case 13:
        case 15:
          assessmentRateShow.assessmentRateForLandAndForestry = true;
          assessmentRateShow.assessmentRateForResidential = true;
          assessmentRateShow.assessmentRateForNonResidential = true;
          assessmentRateShow.assessmentRateForUndeveloped = true;
          break;
      }

      return assessmentRateShow;
    }
  },
  methods: {
    ...mapMutations('grid', ['triggerReload']),

    handleAssessmentRatesMenuClick(args) {
      switch (args.menuId) {
        case 'cm.add_assessment_rate':
            this.resetAssessmentRateModal();

            this.assessmentRatesModalEdit = true;
          break;

        case 'cm.edit_assessment_rate':
          if (args.data[0].prim_uid) {
            this.setAssessmentRateRowDataToModal(args.data[0]);
          }

          this.assessmentRatesModalEdit = true;
          break;

        default:
          this.$emit('menu-clicked', args);
      }
    },
    handleAssessmentRatesColClick(e) {
      if (parseInt(e.row.prim_uid) > 0) {
        this.setAssessmentRateRowDataToModal(e.row);

        this.assessmentRatesModalEdit = true;
      }
    },
    resetAssessmentRateModal() {
      this.assessmentRateModalData = this.getDefaultAssessmentRateModalData();
    },
    getDefaultAssessmentRateModalData() {
      let defaultBaseData = {
        primUid: 0,
        federalStateUid: 0,
        community: ''
      };

      return {
        ...defaultBaseData,
        ...this.getDefaultAssessmentRates(),
      };
    },
    getDefaultAssessmentRates() {
      return {
        landAndForestryAssessmentRate: 0,
        residentialAssessmentRate: 0,
        nonResidentialAssessmentRate: 0,
        undevelopedAssessmentRate: 0,
        builtAndUnbuiltAssessmentRate: 0,
        builtAssessmentRate: 0
      }
    },
    setAssessmentRateRowDataToModal(row) {
      let federalStateUid = parseInt(row.federal_state_uid);

      this.assessmentRateModalData.primUid = parseInt(row.prim_uid);
      this.assessmentRateModalData.federalStateUid = federalStateUid;
      this.assessmentRateModalData.community = row.community;
      this.assessmentRateModalData.landAndForestryAssessmentRate = parseInt(row.land_and_forestry_assessment_rate);
      this.assessmentRateModalData.residentialAssessmentRate = parseInt(row.residential_assessment_rate);
      this.assessmentRateModalData.nonResidentialAssessmentRate = parseInt(row.non_residential_assessment_rate);
      this.assessmentRateModalData.undevelopedAssessmentRate = parseInt(row.undeveloped_assessment_rate);

      if ([1, 2].includes(federalStateUid)) {
        this.assessmentRateModalData.builtAndUnbuiltAssessmentRate = row.residential_assessment_rate;
      } else if ([3, 4, 5, 6, 7, 8, 9, 12, 14, 16].includes(federalStateUid)) {
        this.assessmentRateModalData.builtAssessmentRate = row.residential_assessment_rate;
      }
    },
    fetchCommunityList(federalState) {
      this.loadingCommunity = true;

      this.communityList = [];
      this.$api.get_community_list(federalState.name).then((list) => {
        this.communityList = list.map((o) => {
          return {
            name: o.name + ' - AGS: ' + o.community_key,
            code: o.code,
            community_key: o.community_key
          }
        });

        this.loadingCommunity = false;
      });
    },
    saveAssessmentRates() {
      if (!this.isValidForSaving) {
        return;
      }

      let federalStateUid = parseInt(this.assessmentRateModalData.federalStateUid);
      let residentialAssessmentRate = this.assessmentRateModalData.residentialAssessmentRate;
      let nonResidentialAssessmentRate = this.assessmentRateModalData.nonResidentialAssessmentRate;
      let undevelopedAssessmentRate = this.assessmentRateModalData.undevelopedAssessmentRate;

      if ([1, 2].includes(federalStateUid)) {
        residentialAssessmentRate = this.assessmentRateModalData.builtAndUnbuiltAssessmentRate;
        nonResidentialAssessmentRate = this.assessmentRateModalData.builtAndUnbuiltAssessmentRate;
        undevelopedAssessmentRate = this.assessmentRateModalData.builtAndUnbuiltAssessmentRate;
      } else if ([3, 4, 5, 6, 7, 8, 9, 12, 14, 16].includes(federalStateUid)) {
        residentialAssessmentRate = this.assessmentRateModalData.builtAssessmentRate;
        nonResidentialAssessmentRate = this.assessmentRateModalData.builtAssessmentRate;
      }

      let postData = {
        primUid: this.assessmentRateModalData.primUid,
        federalStateUid: federalStateUid,
        community: this.assessmentRateModalData.community,
        landAndForestryAssessmentRate: this.assessmentRateModalData.landAndForestryAssessmentRate,
        residentialAssessmentRate: residentialAssessmentRate,
        nonResidentialAssessmentRate: nonResidentialAssessmentRate,
        undevelopedAssessmentRate: undevelopedAssessmentRate,
      }

      this.$api.trigger('assessment_rates/save', postData, true).then((response) => {
        if (response.data.success) {
          this.$toast.success(this.$t('settings.taxCategories.assessment_rates.save_msg'));

          this.assessmentRatesModalEdit = false;
          this.resetAssessmentRateModal();
          this.triggerReload(this.assessmentRatesGridId);
          return;
        }

        this.$api.showToast(response.data.message, 'error');
      });
    },
    deleteCallback(response) {
      if (response.data.success) {
        this.triggerReload(this.assessmentRatesGridId);
        this.$toast.success(this.$t('settings.taxCategories.assessment_rates.delete_msg'));
      }
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  max-height: 95vh;
  max-width: 720px;
  width: 80vw;

  .p-dialog-content {
    @include segoe-regular;

    height: fit-content;
    min-height: 350px;
    width: 100%;
    overflow: scroll;
  }

  .p-dialog-footer {
    @apply p-3;
  }
}
</style>
