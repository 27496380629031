const state = {
  currentStep: 1,
  result: {},
  clients: [],
  totalClients: 0,
  checkLocalConnection: true,
  datevLocalParams: {},
  org: [],
  businessPartnerId: '',
  preSelectBranch: 0,
  dataEnvironments: [],
  selectedEnvironments: [],
  showInactiveClients: false,
  importMode: '',
  importKey: ''
};
const getters = {
  getCurrentStep: (state) => state.currentStep,
  getResult: (state) => state.result,
  getClients: (state) => state.clients,
  getTotalClients: (state) => state.totalClients,
  getClientCount: (state) => state.clients.length,
  lookupLocalConnection: (state) => state.checkLocalConnection,
  getDatevLocalParams: (state) => state.datevLocalParams,
  getOrgInfo: (state) => state.org,
  getSelectedBusinessPartner: (state) => state.businessPartnerId,
  getPreSelectBranch: (state) => state.preSelectBranch,
  getDataEnvironments: (state) => state.dataEnvironments,
  getSelectedEnvironments: (state) => state.selectedEnvironments,
  showInactiveClients: (state) => state.showInactiveClients,
  getImportMode: (state) => state.importMode,
  getImportKey: (state) => state.importKey
}

const mutations = {
  init: (state) => {
    state.result = {
        "added": 0,
        "updated": 0,
        "failed": 0,
        "unchanged": 0
    }
    state.clients = []
    state.checkDatevLocalConnection = true;
    state.org = [];
    state.businessPartnerId = '';
    state.preSelectBranch = 0;
    state.dataEnvironments = [];
    state.selectedEnvironments = [];
    state.showInactiveClients = false;
    state.importMode = '';
    state.importKey = '';
  },
  setImportMode: (state, importMode) => {
    state.importMode = importMode;
  },
  setImportKey: (state, importKey) => {
    state.importKey = importKey;
  },
  setCurrentStep: (state, step) => {
    state.currentStep = step
  },
  setTotalClients: (state, count) => {
    state.totalClients = count
  },
  saveResult: (state, result) => {
    state.result = {...state.result, ...result};
  },
  saveClients: (state, clients) => {
    for (let k = 0; k < clients.length; k++) {
      if(state.onlyNewClients) {
        if(typeof clients[k].prim_uid !== 'undefined' && parseInt(clients[k].prim_uid) === 0) {
          state.clients.push(clients[k]);
        }
      } else {
        state.clients.push(clients[k]);
      }
    }
  },
  setDatevLocalParams: (state, localParams) => {
    state.datevLocalParams = localParams
    state.checkLocalConnection = false;
  },
  saveOrgInfo: (state, orgInfo) => {
    state.org = orgInfo
  },
  saveDataEnvironments: (state, dataEnvironments) => {
    state.dataEnvironments = dataEnvironments
  },
  setSelectedEnvironments: (state, selectedEnvironments) => {
    state.selectedEnvironments = selectedEnvironments;
  },
  setBusinessPartner: (state, businessPartnerId) => {
    state.businessPartnerId = businessPartnerId;
  },
  resetBusinessPartner: (state) => {
    state.businessPartnerId = '';
    state.clients = [];
  },
  setPreSelectBranch: (state, branch) => {
    state.preSelectBranch = branch;
  },
  setShowInactiveClients: (state, showInactiveClients) => {
    state.showInactiveClients = showInactiveClients
  }
};

const actions = {};


export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};



