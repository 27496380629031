<template>
    <div id="app">
        <app-modal/>
        <app-loading v-if="!searchLoading"/>
        <router-view v-if="!authVerifying"/>
        <ModalDialog v-show="isModalVisible" :showClose="false">
            <template v-slot:body>
                <div class=" flex flex-col justify-center items-center m-20">
                    <label class="label ">{{ $t('general.resize_window_message_one') }}</label>
                    <label class="label mt-5">{{ $t('general.resize_window_message_two') }}</label>
                    <Button class="mt-5" @click="handleLogout" :text="$t('sidebar.popup.logout')" :secondary="true"/>
                </div>
            </template>
        </ModalDialog>
        <ModalDialog v-show="isDeployModalVisible" modalSize="full-screen" :canEscape="false"
                     :canCloseOutsideClick="false"
                     :showClose="false">
            <template v-slot:body>
                <div class="flex flex-col h-full justify-center items-center">
                    <div class="flex flex-col w-full items-center -mt-10">
                        <img :src="getAssetPath('system_update.svg')" class="update_icon">
                        <p>{{ $t('general.sys_update_message') }}</p>
                    </div>
                </div>
            </template>
        </ModalDialog>
        <!--  dialog support -->
        <ModalDialogRight v-if="isSupportModalVisible" :headerText="$t('general.support.dialogHeader')"
                          :showClose="true" @close="closeSupportDialog">
            <template v-slot:body>
                <div class=" flex flex-col mt-2 h-5/6">
                    <label class="support_label_header text_bold">{{ $t('general.support.header') }}</label>
                    <label class="support_label">{{ $t('general.support.description') }}</label>
                    <div class="flex flex-col space-y-6 m-5 justify-between h-full">
                        <div>
                            <div class="flex">
                                <InputText class="flex-auto" v-model="searchSupport" :requestFocus="true"
                                           @keyup="updateSupportSearchResults"/>
                                <button class=" flex items-center justify-center feedback_button w-8 h-8 mt-1"
                                        @click.prevent="updateSupportSearchResults"
                                >
                                    <img class="w-4 h-4" :src="getAssetPath('arrow-right-white.svg')" alt=""/>
                                </button>
                            </div>

                            <div v-if="searchLoading">
                                <div class="loading-section mt-4">
                                    <i class="pi pi-spin pi-spinner"/>
                                    <div class="label mt-4">
                                        {{ $t('general.support.loading-text') }}
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="searched">
                                <div class="support-search-results h-full" v-if="supportSearchResults.length > 0">
                                    <div class="title">
                                        {{ $t('general.recommended_articles') }}
                                    </div>
                                    <div v-for="(search_result,index) in supportSearchResults" :key="index">
                                        <div class="result">
                                            <a :href="search_result.url" class="black-link" target="_blank">
                                                <div class="flex space-x-3 -mt-2">
                                                    <label
                                                        class="cursor-pointer hover:text-green underline">{{
                                                            search_result.title
                                                        }}
                                                    </label>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="mt-1 text-left">
                                        <br/>
                                        <b>{{ $t('general.support.did-not-find') }}</b><br>
                                        <label class="hover:text-green underline font cursor-pointer"
                                               @click="goToFeedbackSearch">
                                            {{ $t('general.support.search-community-now') }}
                                        </label>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="label mt-6">
                                        {{ $t('general.support.no-result') }}
                                    </div>
                                    <div class="mt-1 text-left">
                                        <label class="hover:text-green underline font cursor-pointer"
                                               @click="goToFeedbackSearch">
                                            {{ $t('general.support.search-community-now') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col mx-5" style="margin-bottom: -95px">
                            <label class="support_bottom_label">{{ $t('general.support.extra_options') }}</label>
                            <hr class=" mt-3 mb-1 hr-line-user"/>
                            <div class="grid grid-cols-2 gap-0 mt-2">

                                <!--                Icon with label for help-->
                                <a href="https://hilfe.grundsteuer-digital.de" class="mb-2 black-link" target="_blank">
                                    <div class="flex iconlabel space-x-3">
                                        <img class="icon icon-filter-input"
                                             :src="require('@/assets/support_help.svg')"/>
                                        <label class="cursor-pointer hover:text-green underline text-left">{{
                                                $t('general.support.help')
                                            }}</label>
                                    </div>
                                </a>
                                <!--                Icon with label for community-->
                                <a href="https://feedback.grundsteuer-digital.de" class="mb-2 black-link"
                                   target="_blank">
                                    <div class="flex iconlabel space-x-3">
                                        <img class="icon icon-filter-input"
                                             :src="require('@/assets/support_compunity.svg')"/>
                                        <label
                                            class="cursor-pointer hover:text-green underline text-left">{{
                                                $t('general.support.community')
                                            }}</label>
                                    </div>
                                </a>
                                <!--                Icon with label for changelog-->
                                <a href="https://changelog.grundsteuer-digital.de" class="mb-2 black-link"
                                   target="_blank">
                                    <div class="flex iconlabel space-x-3">
                                        <img class="icon-changelog icon icon-filter-input"
                                             :src="require('@/assets/support_changelog.svg')"/>
                                        <label
                                            class="cursor-pointer hover:text-green underline text-left">{{
                                                $t('general.support.changelog')
                                            }}</label>
                                    </div>
                                </a>
                                <!--                Icon with label for feedback form-->
                                <div class="flex iconlabel space-x-3" @click="openIssueCreatorDialog">
                                    <img class="icon icon-filter-input"
                                         :src="require('@/assets/support_feedback.svg')"/>
                                    <label
                                        class="cursor-pointer hover:text-green underline text-left">{{
                                            $t('general.support.feedback')
                                        }}</label>
                                </div>
                                <!--                Icon with label for tutorials-->
                                <a href="https://www.youtube.com/@GrundsteuerDigital/videos" class="mb-2 black-link"
                                   target="_blank">
                                    <div class="flex iconlabel space-x-3">
                                        <img class="icon icon-filter-input" :src="require('@/assets/tutorials.svg')"/>
                                        <label class="cursor-pointer hover:text-green underline text-left">{{
                                                $t('general.support.tutorials')
                                            }}</label>
                                    </div>
                                </a>
                                <!--                Icon with label for newsletter-->
                                <a href="https://grundsteuer-digital.de/newsletteranmeldung/" class="mb-2 black-link"
                                   target="_blank">
                                    <div class="flex iconlabel space-x-3">
                                        <img class="icon-changelog icon icon-filter-input"
                                             :src="require('@/assets/newsletter.svg')"/>
                                        <label
                                            class="cursor-pointer hover:text-green underline text-left">{{
                                                $t('general.support.newsletter')
                                            }}</label>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ModalDialogRight>
        <!--  dialog feedback -->

        <ModalDialogRight v-if="isFeedbackModalVisible" :headerText="$t('general.feedback.property-digital-feedback')"
                          modalSize="feedback"
                          :showClose="true" @close="closeFeedbackDialog">
            <template v-slot:body>
                <div class=" flex flex-col mt-2 ">
                    <div class="box_dialog flex justify-start items-center mx-5">
                        <img class="image ml-2" src="@/assets/info_gray.svg"/>
                        <label class="text mx-3">
                            {{ $t('general.feedback.info') }}
                        </label>
                    </div>
                    <div class="flex flex-col m-5">
                        <InputText ref="inputtext" v-model="summary" class="" :label="$t('general.feedback.summary')"
                                   :isRequired="true" :requestFocus="focus"/>

                        <div class="label text-left mt-4">
                            {{ $t('general.feedback.description') }}
                        </div>
                        <div class="box_dialog flex justify-start items-center mt-1">
                            <img class="image ml-2" src="@/assets/info_gray.svg"/>
                            <label class="text mx-3">
                                {{ $t('general.feedback.info-2') }}
                            </label>
                        </div>
                        <InputText v-model="description" :isMultiline="true" class="mt-1"/>
                        <div class="box_dialog flex justify-start items-center mt-5">
                            <img class="image ml-2" src="@/assets/info_gray.svg"/>
                            <label class="text mx-3">
                                {{ $t('general.feedback.info-3') }}
                            </label>
                        </div>
                        <InputUpload v-model="file" ref="file" :accept="allowedTypes" class="mt-3"
                                     :label="$t('general.feedback.attach_file')" @select="fileSelect" multiple/>
                        <InputSelect
                            class="w-full mt-4"
                            :label="$t('general.feedback.salutation')"
                            v-model="salutation"
                            :selected-value="salutation"
                            :options="$t('general.feedback.salutations')"
                            placeholder=""
                        />
                        <InputText v-model="name" :maxLength="50" class="w-full mt-4"
                                   :label="$t('general.feedback.name')"/>
                        <InputText v-model="email" class="w-full mt-4" :maxLength="50"
                                   :label="$t('general.feedback.email')"/>
                        <InputText v-model="telefonnummer" :maxLength="15" class="w-full mt-4"
                                   :label="$t('general.feedback.telefonnummer')"/>
                        <div class="flex mt-6">
                            <Checkbox class="mt-1 mr-2" v-model="feedbackChecked" inputId="city1" name="city"
                                      :binary="true"
                                      @change="getExtraData()"/>
                            <label class="text-left label">{{ $t('general.feedback.check') }}</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex mt-5 mb-5 justify-center space-x-4 pb-10">
                    <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="resetForm"/>
                    <Button class="" :text="$t('buttons.send')" @click="postIssue"/>
                </div>
            </template>
        </ModalDialogRight>
        <!--  dialog term updated-->
        <ModalDialog v-show="isTermVisible" @close="handleTermModalClose" :showClose="false" :canEscape="false"
                     :canCloseOutsideClick="false">
            <template v-slot:body>
                <div class="flex flex-col justify-start ml-4">
                    <div class="flex justify-center items-center">
                        <img class="term_img mt-10" src="@/assets/terms.svg"/>
                    </div>
                    <div class=" flex flex-col justify-center items-center ">
                        <label class="trial_text mt-10">{{ $t('dashboard.term_dialog.title') }}</label>
                        <label class="label mt-4 mr-18 ml-18">{{ $t('dashboard.term_dialog.sub_title') }}</label>
                        <a class="label mt-4 mr-28 ml-28 underline" target="_blank"
                           :href="getTermsLink">{{ $t('dashboard.term_dialog.link') }}</a>
                        <div class="flex mt-10 mb-10 justify-center   w-full">
                            <Button @click="handleLogout" class=" label  bg-green mr-2  text-white"
                                    :text="$t('buttons.logoff')"
                                    :secondary="true"></Button>
                            <Button @click="termsLater" class=" label  bg-green mr-2  text-white"
                                    :text="$t('buttons.later')"
                                    :secondary="true"></Button>
                            <Button @click="acceptTerms" class=" label  bg-green mr-2  text-white"
                                    :text="$t('buttons.agree')"></Button>
                        </div>
                    </div>
                </div>
            </template>
        </ModalDialog>

        <!--  dialog data security updated-->
        <ModalDialog v-show="isDataSecurityVisible" @close="handleDataSecurityModalClose" :showClose="true"
                     :canEscape="false"
                     :canCloseOutsideClick="false">
            <template v-slot:body>
                <div class="flex flex-col justify-start ml-4">
                    <div class="flex justify-center items-center">
                        <img class="term_img mt-10" src="@/assets/terms.svg"/>
                    </div>
                    <div class=" flex flex-col justify-center items-center ">
                        <label class="trial_text mt-10">{{ $t('dashboard.datasecurity_dialog.title') }}</label>
                        <label class="label mt-4 mr-18 ml-18">{{
                                $t('dashboard.datasecurity_dialog.sub_title')
                            }}</label>
                        <a class="label mt-4 mr-28 ml-28 underline" target="_blank"
                           :href="getDataSecurityLink">{{ $t('dashboard.datasecurity_dialog.link') }}</a>
                        <div class="flex mt-10 mb-10 justify-center   w-full">
                            <Button @click="acceptDataSecurity" class=" label  bg-green mr-2  text-white"
                                    :text="$t('general.ok')"></Button>
                        </div>
                    </div>
                </div>
            </template>
        </ModalDialog>

        <!--  dialog account subscription cancelled -->
        <ModalDialog
            v-show="showAccountExpiredDialog"
            :showClose="false"
            :canEscape="false"
            modalSize="small"
            :canCloseOutsideClick="false"
        >
            <template v-slot:body>
                <div class="flex flex-col justify-start ml-4">
                    <div class="flex justify-center items-center">
                        <img
                            class="trial_img mt-5"
                            :src="getAssetPath('trial.svg')"
                        />
                    </div>

                    <div class=" flex flex-col justify-center items-center text-center">
                        <label class="trial_text mt-10">{{ $t('settings.billing.cancellation.login_title') }}</label>

                        <label
                            v-if="hasBillingAccess"
                            class="label mt-4"
                            v-text="$t('settings.billing.cancellation.login_info')"
                        />
                        <label
                            v-else
                            class="label mt-4"
                            v-text="$t('settings.billing.cancellation.login_info_non_admin')"
                        />

                        <div class="flex mt-10 mb-10 justify-center w-full">
                            <Button
                                @click="logout"
                                class="label bg-grey mr-2 text-white"
                                :secondary="true"
                                :text="$t('dashboard.after_trial_end.de_register')"
                            />
                            <Button
                                v-if="hasBillingAccess"
                                @click="goToBilling"
                                class="label mr-2 text-white"
                                :text="$t('dashboard.after_trial_end.choose_package')"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </ModalDialog>

        <!-- Plan deprecation notification dialog -->
        <ModalDialog v-show="showPlanDeprecationDialog"
                     @close="handlePlanDeprecationModalClose"
                     :headerText="$t('dashboard.plan_deprecation.title')"
                     :showClose="true"
                     :canEscape="true"
                     :canCloseOutsideClick="false">
            <template v-slot:body>
                <div class="flex flex-col justify-start ml-2">
                    <label class="trial_text mb-4">
                        {{
                            $t('dashboard.plan_deprecation.sub_title').replace('%plan_end_date%', getCurrentUser().end_date)
                        }}
                    </label>
                    <div class="text w-full">
                        <p class="text-left mb-5 text-sm">
                            {{ $t('dashboard.plan_deprecation.section_1') }}
                        </p>
                    </div>
                    <div class="text w-full">
                        <p class="text-left mb-5 text-sm" v-html="$t('dashboard.plan_deprecation.section_2')"></p>
                    </div>
                    <div class="text w-full">
                        <p class="text-left mb-5 text-sm"
                           v-html="$t('dashboard.plan_deprecation.section_3').replace('%new_plan_start_date%', getCurrentUser().next_start_date)">
                        </p>
                    </div>
                    <div class="text w-full">
                        <p class="text-left mb-5 text-sm">
                            {{
                                $t('dashboard.plan_deprecation.section_4').replace('%plan_acceptance_deadline%', getCurrentUser().end_date)
                            }}
                        </p>
                    </div>
                    <div class="text w-full">
                        <p class="text-left mb-5 text-sm">
                            {{ $t('dashboard.plan_deprecation.section_5') }}
                        </p>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex mt-5 justify-center space-x-4 pb-10">
                    <Button class="label bg-green mr-2 text-white"
                            @click="handleLogout"
                            :text="$t('buttons.logoff')"
                            :secondary="true">
                    </Button>
                    <Button class="label bg-green mr-2 text-white"
                            @click="handlePlanDeprecationModalClose"
                            :text="$t('buttons.later')"
                            :secondary="true">
                    </Button>
                    <Button class="label bg-green mr-2 text-white"
                            @click="acceptFollowupPlan"
                            :text="$t('buttons.agree')">
                    </Button>
                </div>
            </template>
        </ModalDialog>

        <ModalDialog v-show="isNetPromoterScoreModalVisible" @close="closeNetPromoterScoreModal">
            <template v-slot:body>
                <div class="flex flex-col justify-start ml-2">
                    <NetPromoterScoreRating @chooseRating="handleNetPromoterScoreSelected"/>
                </div>
            </template>
        </ModalDialog>

        <a href="#" id="atlwdg-trigger" class="atlwdg-trigger atlwdg-RIGHT"
           @click="openSupportDialog">Support</a>
    </div>
</template>
<script>
import {actionsWoutSessionCheck, themes} from "@/core/constants";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UAParser from 'ua-parser-js'
import Checkbox from 'primevue/checkbox';
import ebnerstolzIcon from "@/assets/themes/ebnerstolz/favicon.png";
import defaultIcon from "@/assets/favicon.png";
import AutoComplete from 'primevue/autocomplete';
import NetPromoterScoreRating from "@/components/inputs/NetPromoterScoreRating";
import moment from 'moment';
import InputSelect from "./components/inputs/InputSelect.vue";

Vue.use(VueAxios, axios)
export default {
    components: {InputSelect, Checkbox, AutoComplete, NetPromoterScoreRating},
    data() {
        return {
            isModalVisible: false,
            isFeedbackModalVisible: false,
            isSupportModalVisible: false,
            focus: false,
            isDeployModalVisible: false,
            isTermVisible: false,
            isDataSecurityVisible: false,
            isNetPromoterScoreModalVisible: false,
            hidePlanDeprecationModal: false,
            searchSupport: '',
            supportSearchResults: [],

            summary: '',
            description: '',
            salutation: '',
            name: '',
            email: '',
            telefonnummer: '',
            allowedTypes: ".jpg,.jpeg,.png.,.csv,.pdf,.xlsx,.xls",
            file: '',
            fileUpload: false,
            filetype: '',
            other: {},
            feedbackIsChecked: false,
            feedbackChecked: false,
            browserCurrent: 'N/A',
            currentPageUrl: 'N/A',
            currentBuildVersion: 'N/A',
            screenResolution: 'N/A',
            os: 'N/A',
            devicePixelRatio: 'N/A',
            windowWidthHeight: 'N/A',
            searchLoading: false,
            searched: false,

            windowActive: true
        }
    },
    async created() {
        let theme = '';
        if (this.isBusiness()) {
            theme = 'business';
        }
        this.authVerifying = true;

        //await this.$recaptchaLoaded();
        //this.$recaptchaInstance.hideBadge();
        await this.checkCurrentUser(!actionsWoutSessionCheck.includes(this.$router.currentRoute.path));

        // Pre order signup handler
        if (!this.isAuthorized() && this.$route.path !== '/register' && this.$route.query.preOrderSignup && this.$route.query.preOrderSignup !== '') {
            let resp = await this.$api.trigger('verify_code/pre_order_signup', {
                'code': this.$route.query.preOrderSignup
            }, true);
            if (resp.data.success) {
                await this.$router.push('/register?preOrderSignup=' + this.$route.query.preOrderSignup);
            } else {
                await this.$router.replace({'query': null});
            }
        }

        // Redirect to new UI
        if (
            !this.isAuthorized()
            && !this.isBusiness()
            && (new URL(window.location.href)).searchParams.get('code') === null
        ) {
            localStorage.setItem('is_new_ui_user', '1');
            window.location.replace(process.env.VUE_APP_UI_V2_URL)
        }

        // Delete account confirmation
        if (this.$route.query.deleteAccount && this.$route.query.deleteAccount !== '') {
            let resp = await this.$api.trigger('delete_account', {
                'code': this.$route.query.deleteAccount
            }, true);
            if (resp.data.success) {
                localStorage.removeItem('tc_logged_in');
                this.$modal.show({
                    headerText: this.$i18n.t('settings.account.delete_account'),
                    text: this.$i18n.t('settings.account.delete_account_confirmed'),
                    showCancel: false,
                    showClose: false,
                    canEscape: false,
                    buttons: {
                        ok: this.$i18n.t('general.ok')
                    },
                    onConfirm: () => {
                        localStorage.removeItem('tc_logged_in');
                        location.href = '/';
                    }
                });
            } else {
                this.$api.showToast(resp.data.message, "error");
            }
        }

        let theme_param = this.$route.query.theme ? this.$route.query.theme : '';
        if (theme_param) {
            theme = theme_param;
            this.authVerifying = false;
        } else if (typeof (this.getCurrentUser().theme) !== 'undefined' && this.getCurrentUser().theme !== '') {
            theme = this.getCurrentUser().theme;
            this.authVerifying = false;
            window.addEventListener("resize", this.resizeHandler);
        } else if (!this.isAuthorized()) {
            // check cookie
            let cookie_val = `; ${document.cookie}`;
            let cookie_parts = cookie_val.split('; partner_key=');
            if (cookie_parts.length === 2) {
                theme = cookie_parts.pop().split(';').shift();
            }

            this.authVerifying = false;
        }

        if (this.$route.query.gclid && this.$route.query.gclid !== '') {
            localStorage.setItem('gclid', this.$route.query.gclid);
        }

        // setup observer
        this.appObserver();

        if (theme !== '') {
            if (this.$isBusiness && typeof themes[theme] !== 'function') {
                theme = 'business';
            }

            this.changeTheme(theme)
        }

        document.addEventListener("visibilitychange", this.windowVisibilityChanged);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
        document.removeEventListener("visibilitychange", this.windowVisibilityChanged);
    },
    computed: {
        ...mapGetters("record_observer", ["getRecordUpdatedTime", "getRecordChangeInfo", "getRecordType", "getDeclarationStatus"]),
        getTermsLink() {
            return process.env.VUE_APP_TERMS_LINK;
        },
        getDataSecurityLink() {
            return process.env.VUE_APP_DATASECURITY_LINK;
        },
        showAccountExpiredDialog() {
            return (
                ![
                    '/setting/billing',
                    '/setting/BillingSubscribe',
                    '/setting/BillingTrial'
                ].includes(this.$route.path)
                && this.getCurrentUser().subscriptionExpired
            );
        },
        showPlanDeprecationDialog() {
            if (
                this.getCurrentUser().main_user !== '1'
                || this.hidePlanDeprecationModal
            ) {
                return false;
            }

            return this.getCurrentUser().show_plan_deprecation_notice;
        },
        hasBillingAccess() {
            return this.hasAccess('billing');
        }
    },
    mounted() {
        this.resizeHandler()
        this.currentPageUrl = window.location.pathname;
    },
    watch: {
        '$store.state.user.currentTheme': {
            deep: false,
            handler: function () {
                this.changeFavicon();
            }
        },
        '$store.state.user.currentUser': {
            deep: true,
            handler: function () {
                if (this.getCurrentUser().showTerms) {
                    this.isTermVisible = true;
                }
                if (this.getCurrentUser().showDataSecurity) {
                    this.isDataSecurityVisible = true;
                }

                setTimeout(() => {
                    if (this.getCurrentUser().hide_net_promoter_score_modal) {
                        return;
                    }

                    this.isNetPromoterScoreModalVisible = this.getCurrentUser().show_net_promoter_score_modal;
                }, 2000);
            }
        },
        '$store.state.user.fusionRole': {
            deep: false,
            handler: function (newValue, oldValue) {
                if (typeof newValue !== 'string') {
                    newValue = '';
                }
                if (typeof oldValue !== 'string') {
                    oldValue = '';
                }

                if (['source', 'target'].includes(newValue) && (oldValue === '')) {
                    setTimeout(() => this.observeFusionRole(), 1000 * 60 * 15);
                }
            }
        },
        getRecordUpdatedTime(newValue, oldValue) {
            if (newValue > 0 && oldValue > 0 && newValue > oldValue) {
                let params = {
                    headerText: this.$t('general.record_observer.title'),
                    title: this.$t('general.record_observer.info', {"username": this.getRecordChangeInfo.userName}),
                    showClose: true,
                    canEscape: true,
                    showCancel: true,
                    onConfirm: () => {
                        this.refreshRecord();
                    },
                    buttons: {
                        "ok": this.$t('general.record_observer.reload'),
                        "cancel": this.$t('general.record_observer.overwrite'),
                        delete: false
                    }
                };

                if (this.getRecordChangeInfo.userName === 'SYSTEM') {
                    params.title = this.$t('general.record_observer.systemInfo');
                    params.showCancel = false
                } else if (this.getRecordType === 'declarations' && this.getDeclarationStatus === 'external_approval_requested' && this.getRecordChangeInfo.status === 'elster_submission') {
                    let clientApprovalStatus = typeof this.getRecordChangeInfo.clientApprovalStatus !== 'undefined' ? parseInt(this.getRecordChangeInfo.clientApprovalStatus) : 0;
                    if (clientApprovalStatus === 2) {
                        params.title = this.$t('general.record_observer.declaration.info', {"username": this.getRecordChangeInfo.userName});
                        params.headerText = this.$t('general.record_observer.declaration.title');
                        params.buttons.ok = this.$t('general.ok');
                        params.showCancel = false;
                    }
                }

                this.$modal.show(params);
            }
        }
    },
    methods: {
        ...mapGetters("user", ["getCurrentUser", "isAuthorized", "getAccessLog"]),
        ...mapMutations("record_observer", ["refreshRecord"]),
        ...mapMutations("user", ["resetAccessLog", "setCurrentUser"]),
        ...mapActions('global', ['observeFusionRole']),

        getVersion() {
            let version = "v" + process.env.PACKAGE_VERSION;
            if (process.env.PACKAGE_REV != null) {
                version = version + '-' + process.env.PACKAGE_REV.substring(0, 8);
            }
            return version;
        },
        changeFavicon() {
            const favicon = document.getElementById("favicon");
            if (this.isBusiness()) {
                if (this.getCurrentTheme() === "ebnerstolz") {
                    favicon.href = ebnerstolzIcon;
                } else {
                    favicon.href = defaultIcon;
                }
            }
        },
        closeSelectColDialog() {
            this.isFeedbackModalVisible = false;
        },
        resetForm() {
            this.summary = "";
            this.description = "";
            this.salutation = "";
            this.name = "";
            this.email = "";
            this.telefonnummer = "";
            this.file = "";

            this.$refs.file.clear();
            this.feedbackChecked = false;
            this.isSupportModalVisible = true;

            this.closeSelectColDialog();
        },
        onResize() {
            let WidthHeight = window.innerWidth + "*" + window.innerHeight;
            return WidthHeight;
        },
        getExtraData() {

            if (this.feedbackChecked) {
                var parser = new UAParser();
                var browserdata = parser.getResult();
                this.feedbackIsChecked = true;
                this.browserCurrent = browserdata.browser.name + ' ' + browserdata.browser.version;
                this.currentPageUrl = window.location.href + this.$route.fullPath;
                this.screenResolution = screen.width + "x" + screen.height;
                this.os = browserdata.os.name + ' ' + browserdata.os.version;
                this.devicePixelRatio = window.devicePixelRatio;
                this.windowWidthHeight = this.onResize();
            } else {
                this.feedbackIsChecked = false;
                this.browserCurrent = 'N/A';
                this.currentPageUrl = 'N/A';
                this.screenResolution = 'N/A';
                this.os = 'N/A';
                this.devicePixelRatio = 'N/A';
                this.windowWidthHeight = 'N/A';
            }
        },
        openSupportDialog() {
            this.isSupportModalVisible = true;
        },
        openIssueCreatorDialog() {
            this.isFeedbackModalVisible = true
            this.focus = true
            this.salutation = this.getCurrentUser().salutation
            this.name = this.getCurrentUser().name
            this.email = this.getCurrentUser().email
            this.telefonnummer = this.getCurrentUser().phone
        },
        fileSelect() {
            this.file = this.$refs.file.files;
            this.fileUpload = true;
        },
        postIssue() {
            this.currentBuildVersion = this.getVersion();
            var data = new FormData();
            data.append('summary', this.summary);
            data.append('description', this.description);
            for (var i = 0; i < this.file.length; i++) {
                let file = this.$refs.file.files[i];
                data.append('file[]', file);
            }
            data.append('browserCurrent', this.browserCurrent);
            data.append('currentPath', this.currentPageUrl);
            data.append('currentBuildVersion', this.currentBuildVersion);
            data.append('screenResolution', this.screenResolution);
            data.append('os', this.os);
            data.append('devicePixelRatio', this.devicePixelRatio);
            data.append('windowWidthHeight', this.windowWidthHeight);
            data.append('feedbackIsChecked', this.feedbackIsChecked);
            data.append('name', this.name);
            data.append('salutation', this.salutation);
            data.append('email', this.email);
            data.append('telefonnummer', this.telefonnummer);

            const headers = {'Content-Type': 'multipart/form-data'};
            if (this.summary) {
                Vue.axios.post("FinoTaxtechIssue", data, {headers}).then(response => {
                    if (response.data.success) {
                        this.summary = "";
                        this.description = "";
                        this.$refs.file.clear();
                        this.$api.showToast(this.$t('general.feedback.success'), 'success');
                        this.closeSelectColDialog();
                    }
                }).catch(error => {
                    this.errorMessage = error.message;
                });
            }
        },
        closeFeedbackDialog() {
            this.isFeedbackModalVisible = false;
            this.focus = false;
        },
        closeSupportDialog() {
            this.isSupportModalVisible = false;
            this.searchSupport = '';
            this.searched = false;
        },
        handleLogout() {
            this.logout()
        },
        resizeHandler() {
            if (!this.$inDevelopment) {
                this.isModalVisible = window.innerWidth < 1000 || window.innerHeight < 500;
            }
        },
        windowVisibilityChanged() {
            if (document.hidden) {
                this.windowActive = false;
            } else {
                this.windowActive = true;
                this.appObserver(); // Trigger explicitly
            }
        },
        appObserver() {
            let accessLogs = this.getAccessLog();
            if (!this.windowActive && Object.keys(accessLogs).length === 0) {
                setTimeout(() => this.appObserver(), this.isAuthorized() ? 60000 : 300000);
                return false;
            }

            let postData = {};
            if (Object.keys(accessLogs).length > 0) {
                postData.accessLog = accessLogs;
            }

            this.$api.trigger('observer', postData).then((response) => {
                if (
                    typeof response !== 'undefined'
                    && typeof response.data !== 'undefined'
                    && response.data.success
                ) {
                    if (response.data.savedLogs) {
                        Object.keys(response.data.savedLogs).forEach((index) => {
                            this.resetAccessLog(response.data.savedLogs[index]);
                        })
                    }
                    if (response.data.systemUpdate) {
                        this.isDeployModalVisible = true;
                    } else if (this.isDeployModalVisible) {
                        location.reload();
                    } else if (response.data.versionChanged) {
                        this.$modal.show({
                            headerText: '',
                            text: this.$i18n.t('general.new_version_info'),
                            showCancel: false,
                            showClose: false,
                            canEscape: false,
                            buttons: {
                                ok: this.$i18n.t('general.ok')
                            },
                            onConfirm: () => {
                                location.reload();
                            }
                        });
                    }
                    if (response.data.onloadError) {
                        this.$api.showToast(response.data.onloadError, "error");
                    }
                }
                setTimeout(() => this.appObserver(), this.isAuthorized() ? 60000 : 300000);
            })
        },
        handleTermModalClose() {
            this.isTermVisible = false
        },
        handlePlanDeprecationModalClose() {
            this.hidePlanDeprecationModal = true;
        },
        termsLater() {
            this.isTermVisible = false;
            this.$api.trigger('terms/ask_later', {});
        },
        acceptTerms() {
            this.$api.trigger('terms/accept', {}, true).then((response) => {
                if (response.data.success) {
                    this.isTermVisible = false;
                }
            });
        },
        acceptFollowupPlan() {
            this.$api.trigger('terms/followup_plan_accept', {}, true).then((response) => {
                if (response.data.success) {
                    this.handlePlanDeprecationModalClose();
                }
            });
        },
        handleDataSecurityModalClose() {
            this.isDataSecurityVisible = false
        },
        acceptDataSecurity() {
            this.$api.trigger('terms/datasecurity_accept', {}, true).then((response) => {
                if (response.data.success) {
                    this.isDataSecurityVisible = false;
                }
            });
        },
        updateSupportSearchResults() {
            this.searchSupport = this.searchSupport.trim();
            this.supportSearchResults = [];
            if (this.searchSupport !== "") {
                this.searchLoading = true;
                this.$api.trigger('search_helpcenter', {search: this.searchSupport}, true).then(
                    (response) => {
                        this.searchLoading = false;
                        this.searched = true;
                        if (typeof response.data.search_results === "object") {
                            let arrResults = Object.values(response.data.search_results);
                            this.supportSearchResults = [...this.supportSearchResults, ...arrResults];
                        }
                    }
                );
            } else {
                this.searched = false;
            }
        },
        goToFeedbackSearch() {
            window.open("https://feedback.grundsteuer-digital.de/search?search=" + this.searchSupport, "_blank");
        },
        goToBilling() {
            if (this.getCurrentUser().billing_by_partner) {
                return false;
            }
            this.$router.push({
                name: "Setting",
                params: {
                    'comp': 'billing',
                    'open_plan_subscription': true
                }
            });
        },
        handleCheckboxClick() {
            this.$router.push('/setting/CreateData')
        },
        handleNetPromoterScoreSelected(rate) {
            this.$api.trigger('user/update_nps_filled_at', {
                'id': this.getCurrentUser().id,
                'account_id': this.getCurrentUser().account_id,
                'account_type': this.getCurrentUser().account_type,
                'user_id': this.getCurrentUser().user_id,
                'rate': rate
            }, true).then((response) => {
                if (response.data.success) {
                    this.closeNetPromoterScoreModal(false);

                    if (typeof response.data.redirect_url !== 'undefined') {
                        window.open(response.data.redirect_url, '_blank');
                    }
                } else {
                    this.$toast.error(response.data.message)
                    return false;
                }
            })
        },
        closeNetPromoterScoreModal(closedUnfilled = true) {
            this.setCurrentUser({hide_net_promoter_score_modal: true});
            this.isNetPromoterScoreModalVisible = false;

            this.$api.trigger('user/register_nps_closed', {
                'user_id': this.getCurrentUser().id,
                'closed_unfilled': closedUnfilled
            }, true).then((response) => {
                if (response.data.success !== true) {
                    this.$toast.error(response.data.message)
                    return false;
                }
            })
        },
    }
}
</script>
<style lang="scss">
:root {
    --primary-color: rgba(34, 157, 86, 1) !important;
    --sidebar-color: theme('colors.green_sidebar') !important;
    --secondary-button-bg-color: #ccccccfe !important;
    --secondary-button-text-color: theme('colors.muted_black') !important;
    --button-text-color: #fff !important;
    --tertiary-color: #FFFFFF !important;
    --success-color: #80b855 !important;
    --warning-color: #eaca44 !important;
    --error-color: #ef4d4d !important;
}

#app {
    font-family: "Segoe UI", "Segoe UI Bold", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.update_icon {
    width: 200px;
}

.atlwdg-trigger.atlwdg-RIGHT {
    left: 100%;
    top: 50%;
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: top left;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: top left;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: top left;
    transform: rotate(90deg);
    transform-origin: top left;
}

.atlwdg-trigger.atlwdg-RIGHT {
    left: 100%;
    top: 50%;
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: top left;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: top left;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: top left;
    transform: rotate(90deg);
    transform-origin: top left;
}

.atlwdg-trigger {
    position: fixed;
    background: #4d4d4d;
    padding: 5px 10px;
    border: 2px solid white;
    border-top: none;
    font-weight: bold;
    color: white !important;
    display: block;
    white-space: nowrap;
    text-decoration: none !important;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 0 0 5px 5px;
}

.box_dialog {
    padding: 10px;
    background-color: #f2f2f2;
    box-sizing: border-box;
}

.image {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
}

.text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: #333333;
    text-align: left;
    line-height: 20px;
    font-size: 15px;
}

.term_img {
    width: 122px;
    height: 122px;
    box-sizing: border-box;
}

.support_label_header {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 500;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 27px;
    margin-top: 28px;
    margin-left: 20px;
    margin-right: 20px;
}

.support_label {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 500;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 15px;
    margin-top: 35px;
    margin-left: 20px;
    margin-right: 20px;
}

.support-search-results {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 500;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 15px;
    margin-top: 35px;
    margin-left: 0;
}

.support-search-results > .title {
    font-size: 16px;
    font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
}

.support-search-results .result {
    margin-bottom: 15px;
}

.support_bottom_label {
    font-size: 16px;
    font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: 700;
    color: #333333;
    text-align: left;
    line-height: normal;
}

.iconlabel {
    margin: 10px 0;
}

.inputSearch input {
    background-image: url("../src/assets/support_download.svg");
    margin-left: 7px;
    padding-left: 27px;
    background-size: 1.2em;
    background-position: left;
    background-repeat: no-repeat;
}

.label {
    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    font-size: 15px;

    &.disabled {
        color: #797979;
    }
}

.loading-section {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.06);

    .pi-spinner {
        font-size: 30px;
        color: $primary;
    }
}

.icon-changelog {
    height: 15px !important;
    margin-top: 5px;
}

a.black-link {
    color: rgba(35, 157, 87, var(--tw-text-opacity))
}

.trial_img {
    width: 141px;
    height: 103px;
    box-sizing: border-box;
}

.trial_text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: 700;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 25px;
    font-size: 20px;

}
</style>
