<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('settings.system.title')" :breadcrumbs="breadcrumbs"/>

    <!-- Data Aacquisition Modal -->
    <ModalDialog v-show="dataAacquisitionDialog"
                 @close="handleModaldataAacquisition"
                 :headerText="$t('settings.taxCategories.data_acquisition_modal.gsd_enable.title')"
                 modalSize="small">
      <template v-slot:body>
        <div class="grid mt-4">
          <div class="flex justify-start items-center pt-4 grey-bg">
            <img class="image ml-2" src="@/assets/info_gray.svg"/>
            <div class="text ml-4">
              <p class="text-left mb-5 text-sm">
                {{ $t('settings.taxCategories.data_acquisition_modal.gsd_enable.text_1') }}
              </p>
              <ul class="text-left ul-list list-disc mb-5 text-xs ml-4">
                <li>{{ $t('settings.taxCategories.data_acquisition_modal.gsd_enable.list.district') }}</li>
                <li>{{ $t('settings.taxCategories.data_acquisition_modal.gsd_enable.list.district_number') }}</li>
                <li>{{ $t('settings.taxCategories.data_acquisition_modal.gsd_enable.list.parcel_number') }}</li>
                <li>{{ $t('settings.taxCategories.data_acquisition_modal.gsd_enable.list.parcel_counter') }}</li>
                <li>{{ $t('settings.taxCategories.data_acquisition_modal.gsd_enable.list.parcel_denominator') }}</li>
                <li>{{ $t('settings.taxCategories.data_acquisition_modal.gsd_enable.list.ground_value') }}</li>
              </ul>
              <div class="text-left text-sm">
                <p v-html="$t('settings.taxCategories.data_acquisition_modal.gsd_enable.text_2')"></p>
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-10 mr-6 w-full">
            <div class="flex mb-4 justify-end w-full space-x-4">
              <Button @click="confirmDataAacquisition(false)"
                      class="w-1/3 mt-3 mb-10 button_cancel"
                      :text="$t('settings.taxCategories.data_acquisition_modal.gsd_enable.button_1')">
              </Button>
              <Button @click="confirmDataAacquisition(true)"
                      class="w-1/3 mt-3 mb-10 bg-green text-white"
                      :text="$t('settings.taxCategories.data_acquisition_modal.gsd_enable.button_2')">
              </Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Data Aacquisition Modal Client Portal dialog -->
    <ModalDialog v-show="dataAacquisitionClientPortalDialog"
                 @close="handleModaldataAacquisitionClientPortal"
                 :headerText="$t('settings.taxCategories.data_acquisition_modal.portal_enable.title')"
                 modalSize="small">
      <template v-slot:body>
        <div class="grid mt-4">
          <div class="flex justify-start items-center pt-4 grey-bg">
            <img class="image ml-2" src="@/assets/info_gray.svg"/>
            <div class="text ml-4 pr-4">
              <div class="text-left text-sm mb-2">
                {{ $t('settings.taxCategories.data_acquisition_modal.portal_enable.text_1') }}
              </div>
              <div class="text-left text-sm bolder mb-2">
                <p v-html="$t('settings.taxCategories.data_acquisition_modal.portal_enable.text_2')"></p>
              </div>
              <div class="text-left text-sm">
                {{ $t('settings.taxCategories.data_acquisition_modal.portal_enable.text_3') }}
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-10 mr-6 w-full">
            <div class="flex mb-1 justify-end w-full space-x-4">
              <Button @click="confirmDataAacquisitionClientPortal(false)"
                      class="w-1/3 mt-3 mb-10 secondary"
                      :text="$t('settings.taxCategories.data_acquisition_modal.portal_enable.button_1')">
              </Button>
              <Button @click="confirmDataAacquisitionClientPortal(true)"
                      class="w-1/3 mt-3 mb-10 bg-green text-white"
                      :text="$t('settings.taxCategories.data_acquisition_modal.portal_enable.button_2')">
              </Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!-- Data Aacquisition Modal Limit dialog -->
    <ModalDialog v-show="dataAacquisitionLimitDialog" @close="handleModaldataAacquisitionLimit"
                 :headerText="$t('settings.taxCategories.data_acquisition_modal.limit_enable.title')" modalSize="small">
      <template v-slot:body>
        <div class="grid mt-4">
          <div class="flex justify-start items-center pt-4 grey-bg">
            <img class="image ml-2" src="@/assets/info_gray.svg"/>
            <div class="text ml-4">
              <div class="text-left text-sm">
                <p v-html="$t('settings.taxCategories.data_acquisition_modal.limit_enable.text_1')"></p>
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-10 mr-6 w-full">
            <div class="flex mb-4 justify-end w-full space-x-4">
              <Button @click="confirmDataAacquisitionLimit(false)"
                      class="w-1/3 mt-3 mb-10 secondary"
                      :text="$t('settings.taxCategories.data_acquisition_modal.portal_enable.button_1')">
              </Button>
              <Button @click="confirmDataAacquisitionLimit(true)"
                      class="w-1/3 mt-3 mb-10 bg-green text-white"
                      :text="$t('settings.taxCategories.data_acquisition_modal.portal_enable.button_2')">
              </Button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!--  Backup  dialog-->
    <ModalDialog v-show="isModalVisible" @close="isModalVisible=false"
                 :headerText="$t('settings.taxCategories.backup')">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4 mt-5">
          <div class=" flex flex-col justify-center items-center ml-20 mr-20 mb-10">
            <div class="finish-check-icon-wrapper">
              <img :src="getAssetPath('check_done.svg')"/>
            </div>
            <label class="label__text mt-2 mr-10 ml-6 ">{{ $t('settings.taxCategories.dialog') }}</label>
            <Button @click="isModalVisible=false" class=" w-1/3 mt-10 mb-10"
                    text="OK"></Button>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!--    Synchronisieren dialog-->
    <ModalDialog v-show="synckDialog" @close="handleModalClose">
      <template v-slot:body>
        <div class="flex flex-col justify-start ml-4">
          <label class="dheader text-left ml-6 mt-4">Synchronisieren</label>
          <!--          show when processing -->
          <div class="mb-20" v-if="isProcessing">
            <div class="flex  justify-center">
              <img class="loader" src="@/assets/loading.svg"/>
            </div>
            <label class="text">Wir führen eine Synchronisierung durch, bitte warten Sie ein paar Minuten.</label>
          </div>
          <!--          show when done-->
          <div class="mb-20" v-else>
            <div class="flex  justify-center">
              <div class="rounded-full flex items-center justify-center bg-green w-24 h-24 flex    m-2">
                <img src="@/assets/check_done.svg"/>
              </div>
            </div>
            <label class="text">Die Syncronisierung ist erfolgreich. </label>
          </div>
        </div>
      </template>
    </ModalDialog>

    <!--    settings dialog-->
    <ModalDialogBilling v-show="settingDialog" @close="handleSettingModalClose">
      <template v-slot:body>
        <div class="flex flex-col w-full justify-start ml-4">
          <label class="dheader text-left ml-6 ">Mandanten mit DATEV synchronisieren</label>
          <!--          radio buttons-->
          <div class="flex flex-col w-full" v-if="showRadio">
            <label class="text-left mt-2 mr-4 mb-4">Möchten Sie einen Mandanten aus DATEV übernehmen oder manuell
              anlegen?<span style="color:red;">*</span></label>

            <div class="flex space-x-3 mb-56  ">
              <div class="flex justify-start items-center cursor-pointer" @click="createOptionSelected('alle')">
                <RadioButton type="radio" v-model="radio" name="radio-button" value="alle" checked/>
                <span class="ml-2 textradio">   Alle Aktiven übernehmen</span>
              </div>
              <div class="flex justify-start items-center cursor-pointer " @click="createOptionSelected('mandanten')">
                <RadioButton type="radio" name="radio-button" v-model="radio" value="mandanten"/>
                <span class="ml-2 textradio">  Mandanten selber auswählen</span>
              </div>

            </div>

          </div>
          <!--          show when alle is selected -->
          <div class="mb-20" v-if="alleStep">
            <div class="flex  justify-center">
              <div class="rounded-full flex items-center justify-center bg-green w-24 h-24 flex    m-2">
                <img src="@/assets/check_done.svg"/>
              </div>

            </div>
            <div class="flex flex-col justify-center items-center ">
              <label class="text">Einstellungen gespeichert. </label>
              <Button class=" button   text-center    bg-green  text-white w-56  "
                      @click="handleSettingModalClose"
                      :text="$t('buttons.close')">

              </Button>
            </div>

          </div>
          <!--          show when mendaten is selected-->
          <div class="mr-6 mb-10">
            <Step2 v-if="mandanten"/>
          </div>

          <!--          buttons-->
          <div class=" flex justify-end mr-8 mb-6" v-show="showRadio">
            <Button class=" " secondary :text="$t('buttons.cancel')">
            </Button>
            <Button class=" " :click="nextClick" :text=" $t('buttons.next')">

            </Button>
          </div>

        </div>
      </template>

    </ModalDialogBilling>

    <!-- crop dialog -->
    <ModalDialog v-show="isCropModalVisible" @close="cancelCrop"
                 :headerText="$t('settings.account.cropDialog.title')">
      <template v-slot:body>
        <div class=" flex flex-col justify-center items-center" style="max-width:630px">
          <cropper
              ref="cropper"
              :src="image.src"
          />
          <div class="flex justify-end space-x-10 mt-4 mr-6 w-full">
            <div class="flex  mb-4 justify-end w-full space-x-4">
              <Button class="" :text="$t('buttons.cancel')" :secondary="true" @click="cancelCrop"/>
              <Button class=" " @click="cropImage" :text="$t('settings.account.cropDialog.button')"/>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

    <AppTabs :categories="$isBusiness ? tabsBusiness : tabsTC" :selected-tab="selectedTab" :save-handler="saveSetting"
             @buttonClick="handleClick">
      <template #tab-pane-software>
        <div v-if="!$isBusiness">
          <div class="flex mt-10  ">
            <InputSelect class="w-1/3" v-model="currentData.tax_office_software" :options="$t('tools')"
                         :selectedValue="currentData.tax_office_software"
                         :label="$t('settings.taxCategories.Kanzlei')"
                         :showHelpIconPopover="true"
                         :popoverTexts="showDisconnectPartner ? [$t('settings.tax_software_help_alt')] : popoverTexts_software"
                         :placeholder="$t('general.please_select')"
            />
          </div>
          <div class="flex mt-5 w-full items-center" v-show="showDisconnectPartner">
            <label class="text-left text-green cursor-pointer flex underline"
                   @click="disconnectPartner">{{ $t('settings.disconnect_partner') }}</label>
            <img class="help_icon flex flex-col" :src="getAssetPath('help_green.svg')"
                 v-tooltip.right="$t('settings.disconnect_partner_help')"/>
          </div>
          <div class=" h-full mt-8" v-if="currentData.tax_office_software === 'hmd-software'">
            <div class="flex justify-start w-1/3">
              <!--    box one-->
              <div class=" setting-rectangle w-full flex flex-col">
                <div class="h-full ">
                  <div class="flex flex-col justify-between">
                    <p class="p-header">{{ $t('settings.system.hmd') }}</p>
                    <div class="flex justify-between mt-5 items-center relative">
                      <InputText :type="password" v-model="currentData.hmd_api_key"
                                 :isRequired="false" :label=" $t('settings.system.api') "
                                 placeholder=""
                                 :showPasswordViewIcon="true"
                                 class="mt-5 mb-1 w-full"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full h-full mt-4 mr-8 pb-8" v-if="currentData.tax_office_software === 'datev'">
            <div class="flex flex-col justify-start  mr-6">
              <InputCheck :text="$t('settings.system.datev_check')"
                          :isChecked="Boolean(currentData.only_datev_client_import)" @check="datevChecked"/>
            </div>
            <div class="flex flex-col mt-4 pb-4 justify-start  mr-6">
              <InputCheck :text="$t('settings.system.datev_dms_enable')"
                          :isChecked="Boolean(currentData.datev_dms_enabled)" @check="dmsChecked"/>
              <!--   -->
              <div class="p-4 mt-4 info-msg flex" v-if="currentData.datev_dms_enabled === 1">
                <img src="@/assets/note.svg" width="18px">
                <label class="pl-4 text-left">
                  {{ $t('settings.system.datev_dms_enable_help') }}
                  <a href="https://apps.datev.de/help-center/documents/1024067"
                     target="_blank">{{ $t('settings.system.datev_dms_enable_help_link') }}</a>
                </label>
              </div>
            </div>
            <div class="flex flex-col justify-start  mr-6">
              <InputCheck :text="$t('settings.system.datev_ggw_enable')"
                          :isChecked="Boolean(currentData.datev_ggw_enabled)" @check="datevGgwChecked"/>
            </div>
            <div class="flex flex-col justify-start mr-6 mt-6">
              <p class="latest">{{ $t('settings.system.datev_login') }}</p>
              <hr class="hr-line-user mt-25 mb-3">
              <div class="text-left mb-3">{{ $t('settings.system.datev_username') }}: {{ currentData.datev_username }}</div>
              <div class="text-left mb-3">{{ $t('settings.system.datev_token_valid') }}: {{ currentData.refresh_token_expires }}</div>
            </div>
            <div class="info flex justify-start items-center">
              <img class="info__icon" :src="getAssetPath('info_gray.svg')"/>
              <label class="info__text" v-html="$t('settings.system.datev_logout_info')"/>
            </div>
            <div class="flex w-full justify-self-start -ml-2">
              <Button
                class="w-64"
                :text="$t('settings.system.datev_logout')"
                :icon="getAssetPath('logout_white.svg')"
                @click="handleDatevLogout"
              />
            </div>
          </div>
          <div class="w-full h-full mt-4 mr-8 pb-8" v-if="currentData.tax_office_software === 'addison-oneclick'">
            <div class="flex flex-col justify-start  mr-6">
              <InputCheck :text="$t('settings.system.addison_ggw_enable')"
                          :isChecked="Boolean(currentData.addison_ggw_enabled)" @check="addisonGgwChecked"/>
              <div class=" flex justify-start -ml-2">
              </div>
            </div>
          </div>
          <div class="w-full h-full mt-8 mr-8 pb-8" v-if="currentData.tax_office_software === 'simba'">
            <div class="flex justify-start w-3/4 mr-6">

            </div>
          </div>
          <div class="w-full h-full mt-8 mr-8 pb-8" v-if="currentData.tax_office_software === 'stotax'">
            <div class="flex justify-start w-3/4 mr-6">

            </div>
          </div>
        </div>
        <div class="w-full h-full mt-8 mr-8 pb-8" v-if="currentData.tax_office_software !== 'datev' || $isBusiness">
          <p class="latest">{{ $t('settings.system.security') }}</p>
          <hr class="hr-line-user mt-25 mb-8">
          <div class="flex flex-col justify-start mr-6">
            <InputCheck :text="$t('settings.system.force_2fa')"
                        :isChecked="Boolean(currentData.force_2fa)" @check="force2faChecked"/>
            <div class=" flex justify-start -ml-2">
            </div>
          </div>
        </div>
        <div class="w-full h-full mr-8 pb-8">
          <p class="latest">{{ $t('settings.system.more_settings') }}</p>
          <hr class="hr-line-user mt-25 mb-8">
          <div class="flex flex-col justify-start mr-6 w-1/2">
            <InputCheck :text="$t('settings.system.auto_generate_client_numbers')"
                        :showHelpIcon="true"
                        :isDisabled="disableAutoGenerateClientNumber"
                        :helpText="$t('settings.system.auto_generate_client_numbers_help')"
                        :isChecked="Boolean(currentData.auto_generate_client_numbers)"
                        @check="autoGenerateClientNumberChecked"/>
            <InputCheck :text="$t('settings.system.disable_property_editing_after_internal_release')"
                        class="mt-5"
                        :showHelpIcon="true"
                        :helpText="$t('settings.system.disable_property_editing_after_internal_release_help')"
                        :isChecked="Boolean(currentData.disable_property_editing_after_internal_release)"
                        @check="propertyEditingChecked"/>
            <InputCheck :text="$t('settings.system.auto_client_permission')"
                        class="mt-5"
                        :showHelpIcon="true"
                        :helpText="$t('settings.system.auto_client_permission_help')"
                        :isChecked="Boolean(currentData.auto_client_permission)" @check="autoClientPermissionChecked"/>
            <div v-show="showPartnerConnect">
              <InputCheck :text="$t('settings.system.auto_account_connection_permission')"
                          class="mt-5"
                          :showHelpIcon="true"
                          :helpText="$t('settings.system.auto_account_connection_permission')"
                          :isChecked="Boolean(currentData.allow_partner_connect)" @check="allowPartnerConnectChecked"/>
            </div>
          </div>
        </div>
        <div class="flex justify-around space-x-8 mt-4">
          <div class="w-full">
            <p class="latest">{{ $t('settings.account.declaration_completeness.title') }}</p>
            <div class="info flex justify-start items-center">
              <img class="info__icon" :src="getAssetPath('info_gray.svg')"/>
              <label class="info__text" v-html="$t('settings.account.declaration_completeness.info')"/>
            </div>
            <div class="flex flex-col space-y-6 mt-4 ">
              <InputCheck :isChecked="currentData.use_custom_declaration_completeness_txt"
                          :text="$t('settings.account.declaration_completeness.use_custom')"
                          @check="toggleDeclarationCompleteness"/>
              <InputText bodyClass="non-resize-textarea w-full"
                         :disabled="!currentData.use_custom_declaration_completeness_txt"
                         :isMultiline="true"
                         :rows="10"
                         v-model="currentData.declaration_completeness"
              />
            </div>
          </div>
        </div>

      </template>

      <!-- Datenbeschaffung-->
      <template #tab-pane-data_acquisition>
        <div class="flex flex-col justify-start">
          <div class="info flex justify-start items-center">
            <img class="info__icon" src="@/assets/info_gray.svg"/>
            <label class="info__text"
             v-html="$t('settings.taxCategories.data_acquisition_help')">
            </label>
          </div>
          <div class="flex pt-4">
            <div class="mt-8">
              <div class="flex flex-col justify-start mb-6">
                <InputCheck :isChecked="Boolean(currentData.data_acquisition)" @check="dataAacquisitionChecked"/>
                <div class=" flex justify-start ml-8">
                  {{ $t('settings.taxCategories.data_acquisition') }}
                </div>
              </div>
              <div class="flex flex-col justify-start mb-6" v-show="isDataAacquisitionChecked === 1"
                   v-if="!$isBusiness">
                <InputCheck @check="dataAacquisitionClientPortalChecked"
                            :isChecked="Boolean(currentData.data_acquisition_client_portal)"/>
                <div class=" flex justify-start ml-8">
                  {{ $t('settings.taxCategories.data_acquisition_client_portal') }}
                </div>
              </div>
              <div class="flex flex-col justify-start mb-6" v-show="isDataAacquisitionChecked === 1">
                <InputCheck :isChecked="Boolean(currentData.data_acquisition_limit)"
                            @check="dataAacquisitionLimitChecked"/>
                <div class=" flex justify-start ml-8">
                  {{ $t('settings.taxCategories.data_acquisition_limit') }}
                </div>
              </div>
              <div class="flex flex-col justify-start mb-4" v-if="dataAacquisitionLimitAmount"
                   v-show="isDataAacquisitionChecked === 1">
                <div class=" flex justify-start">
                  {{ $t('settings.taxCategories.data_acquisition_limit_amount') }}
                </div>
                <InputText v-model="currentData.data_acquisition_limit_amount"
                           :maxLength="5"
                           :isFloat="true"
                           :isError="invalidDataAcquisitionLimitAmount"
                           :errorMessage="$t('settings.taxCategories.data_acquisition_limit__min_amount_error')"
                           placeholder="500"
                           @input="checkEmpty"
                           style="width: 457px;"/>
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-start" v-show="isDataAacquisitionChecked === 1">
            <div class="flex verflow-x-auto sm:-mx-2 lg:-mx-8 ">
              <div class="inline-block min-w-full sm:px-4 lg:px-8 ">
                <div class="overflow-hidden ">
                  <table class="w-3/12 border-b" style="width: 457px;"> <!-- border border-l border-gray_light -->
                    <thead class="border border-l border-gray_light">
                    <tr>
                      <th colspan="3" scope="col" class="text-md px-4 py-4 text-left grey-bg">
                        {{ $t('settings.taxCategories.data_acquisition_overview_title') }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border border-l border-gray_light">
                      <td class="px-4 py-4 text-left">
                        {{ $t('settings.taxCategories.data_acquisition_overview_title_month') }}
                      </td>
                      <td class="text-left">
                        {{ currentData.data_acquisition_month_total_items }}
                      </td>
                      <td class="text-left">
                        {{ $t('settings.taxCategories.data_acquisition_overview_title_month_items') }}
                      </td>
                    </tr>
                    <tr class="border border-l border-gray_light">
                      <td class="px-4 py-4 text-left">
                        {{ $t('settings.taxCategories.data_acquisition_overview_title_costs') }}
                      </td>
                      <td class="text-left w-1/6 ">
                        {{ data_acquisition_month_total_price_view }}
                      </td>
                      <td class="text-left">
                        {{ $t('settings.taxCategories.data_acquisition_overview_title_costs_items') }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </template>

      <template #tab-pane-client_portal v-if="!$isBusiness">
        <div class="flex justify-around space-x-8 ">
          <div class="w-full">
            <div class="flex flex-col">
              <div class="flex  ">
                <div class="flex flex-col  justify-start items-center">
                  <div class="flex pt-4">
                    <p class="photo-text">
                      {{ $t('settings.account.company_logo') }}
                    </p>
                    <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                               :hideTriggers="['click']">
                      <!-- This will be the popover target (for the events and position) -->
                      <img class="help_icon" :src="getAssetPath('help_green.svg')"/>
                      <!-- This will be the content of the popover -->
                      <template #popper>
                        <div class="tooltip-content">
                          <p class="space-y-2 mb-1 popover-text"
                             v-for="text in [$t('settings.account.company_logo_help')]"
                             v-bind:key="text">
                            {{ text }}
                          </p>
                        </div>
                      </template>
                    </VDropdown>
                  </div>
                  <div class="rounded-full mb-5" v-if="currentUser.companyLogoPic">
                    <img class="profile-pic selected" :src="currentUser.companyLogoPic"/>
                  </div>
                  <div class="rounded-full mb-5" v-else>
                    <img class="profile-pic" :src="defaultCompanyLogoPic"/>
                  </div>
                </div>
              </div>
              <div class="flex space-x-2">
                <Button class="profile-button " @click="chooseCompanyLogo" :text="$t('settings.account.upload') "/>
                <input
                    type="file"
                    style="display: none"
                    ref="fileInputCompany"
                    accept="image/*"
                    @change="photoSelect"
                />
                <Button v-if="currentUser.companyLogoPic" class=" profile-button "
                        @click="deletePic()"
                        :text="$t('settings.account.delete')"/>
              </div>
              <div class="flex flex-col mt-3 mb-2">
                <label class="date_format">{{ $t('settings.system.client_portal.file_types') }}: <span
                    style="font-weight: bold">jpg, png, gif</span>
                </label>
                <label class="date_format mt-2 ">{{ $t('settings.system.client_portal.dimensions1') }} <span
                    style="font-weight: bold">30 x 30</span></label>
                <label class="date_format ">{{ $t('settings.system.client_portal.dimensions2') }} <span
                    style="font-weight: bold">5000 x 5000</span></label>
              </div>
              <div class="flex space-x-2 mt-4">
                <div class="flex flex-col">
                  <label class="label text-left mb-1">{{ $t('settings.taxCategories.color_picker_main') }}</label>
                  <div class="color-wrapper flex justify-between items-center " @click="handlePickerOneClick">
                    <v-input-colorpicker ref="pickerOne" v-model="currentData.client_portal_main_color"/>
                    <img class="arrow mr-1" src="@/assets/color_arrow_down.svg"/>
                  </div>
                </div>
                <div class="flex flex-col">
                  <label class="label text-left mb-1">{{ $t('settings.taxCategories.color_picker_color') }}</label>
                  <div class="color-wrapper flex justify-between items-center " @click="handlePickerTwoClick">
                    <v-input-colorpicker ref="pickerTwo" v-model="currentData.client_portal_font_color"/>
                    <img class="arrow mr-1" src="@/assets/color_arrow_down.svg"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="box-client-portal mt-2">
              <div class="info flex justify-start items-center">
                <img class="info__icon" src="@/assets/info_gray.svg"/>
                <label class="info__text">{{ $t('settings.system.client_portal.info') }} </label>
              </div>
              <div class="flex flex-col space-y-6 mt-4 ">
                <InputText class="w-full"
                           :disabled="Boolean(currentData.use_branch_data)"
                           :label="$t('settings.account.fields.phone')"
                           :maxLength="16"
                           v-model="currentData.client_portal_phone"
                           placeholder=""/>
                <InputText class="w-full" :disabled="Boolean(currentData.use_branch_data)"
                           :label="$t('settings.account.fields.email')" v-model="currentData.client_portal_email"
                           placeholder=""/>
                <InputCheck :isChecked="Boolean(currentData.use_branch_data)"
                            :text="$t('settings.system.client_portal.use_branch_data')" @check="selectedUseBranchData"/>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-around space-x-8 mt-8 mb-10">
          <div class="w-full">
            <p class="latest">{{ $t('settings.account.declaration_completeness.title') }}</p>
            <div class="info flex justify-start items-center">
              <img class="info__icon" :src="getAssetPath('info_gray.svg')"/>
              <label class="info__text" v-html="$t('settings.account.declaration_completeness.info')"/>
            </div>
            <div class="flex flex-col space-y-6 mt-4 ">
              <InputCheck :isChecked="currentData.use_custom_declaration_completeness_txt"
                          :text="$t('settings.account.declaration_completeness.use_custom')"
                          @check="toggleDeclarationCompleteness"/>
              <InputText bodyClass="non-resize-textarea w-full"
                         :disabled="!currentData.use_custom_declaration_completeness_txt"
                         :isMultiline="true"
                         :rows="10"
                         v-model="currentData.declaration_completeness"
              />
            </div>
          </div>
        </div>
      </template>

      <template #tab-pane-email v-if="!$isBusiness">
        <div class="flex justify-around space-x-10">
          <div class="info flex w-full justify-start items-center mb-4 ">
            <img class="info__icon" src="@/assets/info_gray.svg"/>
            <label class="info__text">{{ $t('settings.taxCategories.description_email') }}</label>
          </div>
        </div>

        <div class="flex justify-around space-x-8 mt-2 mb-2">
          <div class="w-full">
            <p class="latest">{{ $t('settings.taxCategories.access_to_the_client_portal') }}</p>
            <div class="flex flex-col space-y-4 mt-4 ">
              <InputCheck :isChecked="currentData.use_access_to_the_client_portal_txt"
                          :text="$t('settings.taxCategories.customize_email_text')"
                          @check="toggleAccessToClientPortal"/>
              <div>
                <p class="text-left mt-4 mb-2 label">{{ $t('settings.taxCategories.personal_message') }}</p>
                <InputText bodyClass="non-resize-textarea w-full"
                           :disabled="!currentData.use_access_to_the_client_portal_txt"
                           :isMultiline="true"
                           :rows="15"
                           v-model="currentData.access_to_the_client_portal"
                           style="min-height: 300px;"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-around space-x-8 mt-2 mb-2">
          <div class="w-full">
            <p class="latest">{{ $t('settings.taxCategories.prompt_clients_to_create_plots_of_land') }}</p>
            <div class="flex flex-col space-y-4 mt-4 ">
              <InputCheck :isChecked="currentData.use_prompt_clients_to_create_plots_of_land_txt"
                          :text="$t('settings.taxCategories.customize_email_text')"
                          @check="togglePromptClientsToCreatePlotsOfLand"/>
              <div>
                <p class="text-left mt-4 mb-2 label">{{ $t('settings.taxCategories.personal_message') }}</p>
                <InputText bodyClass="non-resize-textarea w-full"
                           :disabled="!currentData.use_prompt_clients_to_create_plots_of_land_txt"
                           :isMultiline="true"
                           :rows="10"
                           v-model="currentData.prompt_clients_to_create_plots_of_land"
                           style="min-height: 200px;"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-around space-x-8 mt-2 mb-2">
          <div class="w-full">
            <p class="latest">{{ $t('settings.taxCategories.release_by_email') }}</p>
            <div class="flex flex-col space-y-4 mt-4 ">
              <InputCheck :isChecked="currentData.use_release_by_email"
                          :text="$t('settings.taxCategories.customize_email_text')" @check="toggleReleaseByEmail"/>
              <div>
                <p class="text-left mt-4 mb-2 label">{{ $t('settings.taxCategories.personal_message') }}</p>
                <InputText bodyClass="non-resize-textarea w-full"
                           :disabled="!currentData.use_release_by_email"
                           :isMultiline="true"
                           :rows="10"
                           v-model="currentData.release_by_email"
                           style="min-height: 200px;"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-around space-x-8 mt-2 mb-2">
          <div class="w-full">
            <p class="latest">{{ $t('settings.taxCategories.send_document') }}</p>
            <div class="flex flex-col space-y-4 mt-4 ">
              <InputCheck :isChecked="currentData.use_send_document"
                          :text="$t('settings.taxCategories.customize_email_text')" @check="toggleSendDocument"/>
              <div>
                <p class="text-left mt-4 mb-2 label">{{ $t('settings.taxCategories.personal_message') }}</p>
                <InputText bodyClass="non-resize-textarea w-full"
                           :disabled="!currentData.use_send_document"
                           :isMultiline="true"
                           :rows="10"
                           v-model="currentData.send_document"
                           style="min-height: 200px;"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-around space-x-8 mt-2 mb-2">
          <div class="w-full">
            <p class="latest">{{ $t('settings.taxCategories.query_clients_to_change_property') }}</p>
            <div class="flex flex-col space-y-4 mt-4 ">
              <InputCheck :isChecked="currentData.use_query_clients_to_change_property"
                          :text="$t('settings.taxCategories.customize_email_text')"
                          @check="toggleQueryClientsToChangeProperty"/>
              <div>
                <p class="text-left mt-4 mb-2 label">{{ $t('settings.taxCategories.personal_message') }}</p>
                <InputText bodyClass="non-resize-textarea w-full"
                           :disabled="!currentData.use_query_clients_to_change_property"
                           :isMultiline="true"
                           :rows="20"
                           v-model="currentData.query_clients_to_change_property"
                           style="min-height: 200px;"
                />
              </div>
            </div>
          </div>
        </div>

      </template>

      <template #tab-pane-appeal >
        <div class="flex justify-around space-x-10">
          <div class="info flex w-full justify-start items-center mb-4 ">
            <img class="info__icon" src="@/assets/info_gray.svg"/>
            <label class="info__text">{{ $t('settings.taxCategories.description_appeal') }}</label>
          </div>
        </div>
        <div class="flex justify-around space-x-8 mt-2 mb-2">
          <div class="w-full">
            <p class="latest">{{ $t('settings.taxCategories.elster_message_appeal_subject') }}</p>
            <div class="flex flex-col space-y-4 mt-4 ">
              <InputCheck :isChecked="currentData.use_elster_message_appeal_subject"
                          :text="$t('settings.taxCategories.adjust_elster_message_appeal_subject')" @check="toggleElsterMessageAppealSubject"/>
              <div>
                <InputText bodyClass="non-resize-textarea w-full"
                           :disabled="!currentData.use_elster_message_appeal_subject"
                           :maxLength="99"
                           v-model="currentData.elster_message_appeal_subject"
                           :infoText="$t('general.errors.limitDesc').replace('%length%', currentData.elster_message_appeal_subject.length).replace('%maxLimit%', 99)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-around space-x-8 mt-2 mb-2">
          <div class="w-full">
            <p class="latest">{{ $t('settings.taxCategories.elster_message_appeal_content') }}</p>
            <div class="flex flex-col space-y-4 mt-4 ">
              <InputCheck :isChecked="currentData.use_elster_message_appeal_content"
                          :text="$t('settings.taxCategories.adjust_elster_message_appeal_content')" @check="toggleElsterMessageAppealContent"/>
              <div>
                <InputText bodyClass="non-resize-textarea w-full"
                           :disabled="!currentData.use_elster_message_appeal_content"
                           :isMultiline="true"
                           :rows="15"
                           v-model="currentData.elster_message_appeal_content"
                          style="min-height: 200px;"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #tab-pane-backup>
        <div class="flex justify-around space-x-10">
          <div class="box2 flex w-full justify-start items-center mb-10 ">
            <img class="image-box ml-2" src="@/assets/info_gray.svg"/>
            <label class="text-box ml-2">{{ $t('settings.taxCategories.description_backup') }}</label>
          </div>
        </div>
        <div class="space-y-4 flex flex-col justify-start">
          <div class="flex w-full justify-self-start -ml-2">
            <Button class="w-64 " @click="isModalVisible=true" :text="$t('settings.taxCategories.backup_button')"
                    :icon="getAssetPath('backup_button_right.svg')"/>
          </div>
          <div class="flex flex-col justify-start">
            <p class="latest">{{ $t('settings.taxCategories.grid_label') }}</p>
            <div class="w-full mt-8" style="height: calc(100vh - 270px)">
              <DataTable :value="drafts" :lazy="true" :filters="filters"
                         :totalRecords="totalRecords"
                         :scrollable="true" scrollHeight="flex"
                         sortField="created_at" :sortOrder="-1"
                         :virtual-scroll="true"
                         :virtualRowHeight="40"
                         :loading="gridLoading"
                         :resizableColumns="resizableColumns"
                         :rows="rows"
                         styleClass="tdLessPadding"
                         bodyStyle="padding-right: -17px"
                         :reorderableColumns="reorderableColumns"
              >
                <Column v-for="(col,index) of selectedColumns" :field="col.field" :header="col.header"
                        :key="col.field + '_' + index"
                        :sortable="true">

                  <template #body="slotProps" v-if="index===2">
                    <label class="pl-5" v-if="slotProps.data.image===''">Wird erstellt..</label>
                    <div v-else class="pl-10 flex justify-start items-start cursor-pointer">
                      <img class="column_icon" :src="slotProps.data.image"/>

                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </template>

      <template #tab-pane-assessment_rates>
        <AssessmentRateTable/>
      </template>

      <template #tab-pane-reorg v-if="!$isBusiness && isAccountReorganisationAllowed">
        <ReorgStart
          :maxReorgOptions="1"
          preSelectionValue="fusion">
        </ReorgStart>
      </template>
    </AppTabs>
  </div>
</template>

<script>
import AppTabs from "@/components/AppTabs";
import AssessmentRateTable from '@/components/AssessmentRate/AssessmentRateTable';
import ReorgStart from "@/components/AccountReorganisation/ReorgStart";
import Tooltip from 'primevue/tooltip';
import ModalDialogBilling from '@/components/ModalDialogBilling.vue';
import Step2 from "@/components/datev/data_steps/Step2"
import {mapGetters} from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import RadioButton from 'primevue/radiobutton';
import InputColorPicker from 'vue-native-color-picker'
import {Cropper} from "vue-advanced-cropper";
import {UserService} from "../../services/user.service";
import {appMixin} from "@/core/utils/mixin";
import {userPermissions} from "../../core/constants";
import moment from "moment";

export default {
  name: "systemsettings",
  props: {
    isDisabled: {
      type: Boolean,
      default: true
    },
  },
  mixins: [appMixin],
  components: {
    ModalDialogBilling,
    Step2,
    DataTable,
    Column,
    AppTabs,
    AssessmentRateTable,
    ReorgStart,
    RadioButton,
    Cropper,
    "v-input-colorpicker": InputColorPicker
  },
  directives: {'tooltip': Tooltip},
  data() {
    return {
      popoverTexts: [this.$t('settings.account.company_logo_help')],
      popoverTexts_software: [this.$t('settings.system.tooltip.software')],
      popoverLink: 'https://hilfe.grundsteuer-digital.de/faq/mandantenportal/',
      userService: new UserService(),
      isModalVisible: false,
      gridLoading: false,
      totalRecords: 0,
      gridParams: {},
      selectedDate: '',
      filters: {},
      columns: [
        {field: 'id', header: this.$t('settings.taxCategories.table.col1'), isChecked: true},
        {field: 'client', header: this.$t('settings.taxCategories.table.col2'), isChecked: true},
        {field: 'street', header: this.$t('settings.taxCategories.table.col3'), isChecked: true}
      ],
      selectedTab: '',
      selectedColumns: null,
      columnResizeMode: 'fit',
      reorderableColumns: true,
      resizableColumns: true,
      rows: 10,
      defaultCompanyLogoPic: require("@/assets/CompanyLogo.png"),
      currentUser: {
        companyLogoPic: this.$store.state.user.currentUser.companyLogoPic
      },
      image: {
        name: "",
        src: "",
        type: ""
      },
      isCropModalVisible: false,

      drafts: [
        {
          id: "04.09.2021 11:32",
          client: '-',
          street: 'Wird erstellt..',
          image: ''

        },
        {
          id: "04.09.2021 11:32",
          client: '60',
          street: 'Universitätsplatz 12, 34127',
          image: this.getAssetPath('download_backup.svg')

        },
        {
          id: "04.09.2021 11:32",
          client: '70',
          street: 'Universitätsplatz 12, 34127',
          image: this.getAssetPath('download_backup.svg')

        },
        {
          id: "04.09.2021 11:32",
          client: '80',
          street: 'Universitätsplatz 12, 34127',
          image: this.getAssetPath('download_backup.svg')

        },
        {
          id: "04.09.2021 11:32",
          client: '100',
          street: 'Universitätsplatz 12, 34127',
          image: this.getAssetPath('download_backup.svg')

        },
        {
          id: "04.09.2021 11:32",
          client: '150',
          street: 'Universitätsplatz 12, 34127',
          image: this.getAssetPath('download_backup.svg')

        },


      ],
      eyeIcon: require('@/assets/eye_green.svg'),
      eyeIconCut: require('@/assets/eye_green-cut.svg'),
      tabsTC: [
        {
          name: this.$t('settings.taxCategories.software'),
          slug: 'software'
        },
        {
          name: this.$t('settings.taxCategories.data_acquisitions'),
          slug: 'data_acquisition'
        },
        {
          name: this.$t('settings.taxCategories.client_portal'),
          slug: 'client_portal'
        },
        {
          name: this.$t('settings.taxCategories.Einstellungen'),
          slug: 'email'
        },
        {
          name: this.$t('settings.taxCategories.appeal'),
          slug: 'appeal'
        },
        {
          name: this.$t('settings.taxCategories.assessment_rates.title'),
          slug: 'assessment_rates'
        },
      ],
      tabsBusiness: [
        {
          name: this.$t('settings.taxCategories.software'),
          slug: 'software'
        },
        {
          name: this.$t('settings.taxCategories.data_acquisitions'),
          slug: 'data_acquisition'
        },
        {
          name: this.$t('settings.taxCategories.appeal'),
          slug: 'appeal'
        }
        /*{
          name: this.$t('settings.taxCategories.backup'),
          slug: 'backup'
        }*/
      ],
      currentData: {
        reminder1: "7",
        reminder2: "14",
        reminder3: "21",
        hmd_api_key: "",
        only_datev_client_import: 0,
        datev_dms_enabled: 0,
        datev_ggw_enabled: 0,
        addison_ggw_enabled: 0,
        tax_office_software: "",
        force_2fa: 0,
        client_portal_main_color: '',
        client_portal_font_color: '',
        client_portal_email: '',
        client_portal_phone: '',
        use_branch_data: 0,
        data_acquisition: 0,
        data_acquisition_client_portal: 0,
        data_acquisition_limit: 0,
        data_acquisition_limit_amount: 0,
        data_acquisition_month_total_items: 0,
        data_acquisition_month_total_price: 0,
        isDisabled: true,
        declaration_completeness: this.$t('settings.account.declaration_completeness.pdf_text'),
        use_custom_declaration_completeness_txt: false,
        access_to_the_client_portal: '',
        use_access_to_the_client_portal_txt: false,
        prompt_clients_to_create_plots_of_land: '',
        use_prompt_clients_to_create_plots_of_land_txt: false,
        query_clients_to_change_property: '',
        use_query_clients_to_change_property: false,
        release_by_email: '',
        use_release_by_email: false,
        send_document: '',
        use_send_document: false,
        elster_message_appeal_subject: '',
        use_elster_message_appeal_subject: false,
        elster_message_appeal_content: '',
        use_elster_message_appeal_content: false,
        auto_generate_client_numbers: 0,
        auto_client_permission: 0,
        allow_partner_connect: 0,
        disable_property_editing_after_internal_release: false,
        partner: ''
      },
      password: 'password',

      radio: 'alle',
      showRadio: true,
      alle: true,
      alleStep: false,
      mandanten: false,
      synckDialog: false,
      settingDialog: false,
      dataAacquisitionDialog: false,
      dataAacquisitionClientPortalDialog: false,
      dataAacquisitionLimitDialog: false,
      dataAacquisitionLimitAmount: false,
      dataAacquisitionLimit: true,
      data_acquisition_limit_amount_view : '0',
      data_acquisition_month_total_price_view: '0',
      data_acquisition_month_total_price : 0,
      isProcessing: true,
      isDataAacquisitionChecked: 0,
      breadcrumbs: [
        {
          title: this.$t('settings.system.menu.setting'),
          link: ''
        },
        {
          title: this.$t('settings.system.menu.system'),
          link: ''
        }
      ],
      invalidDataAcquisitionLimitAmount: false
    }
  },
  created() {
    let userAccess = userPermissions[this.getUserRole()];
    if (userAccess.length === 0 || !userAccess.includes('system_settings')) {
      this.$router.push('/home');
    }

    this.selectedColumns = this.columns;
    this.tempselectedColumns = this.columns;
  },
  computed: {
    isAccountReorganisationAllowed() {
      if (this.$inDevelopment === true) {
        return true;
      }

      if (this.getCurrentUser().account_type === 'tax_consultant') {
        return true;
      }

      return false;
    },
    label1() {
      return '1. ' + this.$t('settings.taxCategories.selectLabel')
    },
    label2() {
      return '2. ' + this.$t('settings.taxCategories.selectLabel')
    },
    label3() {
      return '3. ' + this.$t('settings.taxCategories.selectLabel')
    },
    disableAutoGenerateClientNumber() {
      return this.currentData.tax_office_software === 'datev' && parseInt(this.currentData.only_datev_client_import) === 1;
    },
    showPartnerConnect() {
      return !this.currentData.partner
    },
    showDisconnectPartner() {
      let softwares = ['datev', 'addison-oneclick', 'andere', 'none'];
      let allowed_roles = ['administrator', 'it_administrator'];
      return this.currentData.partner !== '' && this.currentData.tax_office_software !== '' && !softwares.includes(this.currentData.tax_office_software) && allowed_roles.includes(this.getCurrentUser().role);
    }
  },
  watch: {
    currentData: {
      handler(val) {
        if (val.data_acquisition_limit_amount === '') {
          val.data_acquisition_limit_amount = 0;
        }

        this.data_acquisition_month_total_price_view = new Intl.NumberFormat('de-DE', {'minimumFractionDigits': 2}).format(val.data_acquisition_month_total_price);
        if (!this.$isBusiness) {
          if (val.tax_office_software === 'datev') {
            val.force_2fa = 0;
          }
        }
      },
      deep: true
    },

    'currentData.data_acquisition_limit_amount': {
      handler(val) {
        if (!Number.isInteger(val) && val.includes('.')) {
          this.currentData.data_acquisition_limit_amount = new Intl.NumberFormat('de-DE', {'minimumFractionDigits': 2}).format(val);
        }
      }
    }

  },
  beforeMount() {
    this.selectedTab = 'software';
    let requestedTab = '';
    if (this.$route.query.tab) {
      requestedTab = this.$route.query.tab;
    } else if (this.$route.params.tab) {
      requestedTab = this.$route.params.tab;
    }
    if (requestedTab) {
      let tabs = this.isBusiness() ? this.tabsBusiness : this.tabsTC;
      let foundTab = tabs.filter((o) => {
        return o.slug === requestedTab;
      })
      if (foundTab.length > 0) {
        this.selectedTab = foundTab[0].slug
      }
    }
  },
  mounted() {
    if (!this.$isBusiness) {
      this.currentData.hmd_api_key = this.getCurrentUser().settings.hmd_api_key ? this.getCurrentUser().settings.hmd_api_key : '';
      this.currentData.tax_office_software = this.getCurrentUser().settings.tax_office_software ? this.getCurrentUser().settings.tax_office_software : '';
      this.currentData.reminder1 = this.getCurrentUser().settings.reminder1 ? this.getCurrentUser().settings.reminder1 : '0';
      this.currentData.reminder2 = this.getCurrentUser().settings.reminder2 ? this.getCurrentUser().settings.reminder2 : '0';
      this.currentData.reminder3 = this.getCurrentUser().settings.reminder3 ? this.getCurrentUser().settings.reminder3 : '0';
      this.currentData.client_portal_main_color = this.getCurrentUser().settings.client_portal_main_color ? this.getCurrentUser().settings.client_portal_main_color : '';
      this.currentData.client_portal_font_color = this.getCurrentUser().settings.client_portal_font_color ? this.getCurrentUser().settings.client_portal_font_color : '';
      this.currentData.client_portal_phone = this.getCurrentUser().settings.client_portal_phone ? this.getCurrentUser().settings.client_portal_phone : '';
      this.currentData.client_portal_email = this.getCurrentUser().settings.client_portal_email ? this.getCurrentUser().settings.client_portal_email : '';
      this.currentData.use_branch_data = this.getCurrentUser().settings.use_branch_data ? parseInt(this.getCurrentUser().settings.use_branch_data) : 0;
      this.currentData.only_datev_client_import = this.getCurrentUser().settings.only_datev_client_import ? this.getCurrentUser().settings.only_datev_client_import : 0;
      this.currentData.datev_dms_enabled = this.getCurrentUser().settings.datev_dms_enabled ? this.getCurrentUser().settings.datev_dms_enabled : 0;
      this.currentData.datev_ggw_enabled = this.getCurrentUser().settings.datev_ggw_enabled ? this.getCurrentUser().settings.datev_ggw_enabled : 0;

      if (typeof this.getCurrentUser().datev !== 'undefined') {
        this.currentData.datev_username = this.getCurrentUser().datev.account_name ? this.getCurrentUser().datev.account_name : '';
        let timestamp = this.getCurrentUser().datev.refresh_token_expires ? this.getCurrentUser().datev.refresh_token_expires * 1000 : 0;
        if (timestamp > 0) {
          this.currentData.refresh_token_expires = moment(timestamp, 'x').format('DD.MM.YYYY HH:mm');
        } else {
          this.currentData.refresh_token_expires = '--';
        }
      }

      this.currentData.addison_ggw_enabled = this.getCurrentUser().settings.addison_ggw_enabled ? this.getCurrentUser().settings.addison_ggw_enabled : 0;
      this.currentData.data_acquisition_client_portal = this.getCurrentUser().settings.data_acquisition_client_portal ? 1 : 0;
      if (this.getCurrentUser().settings.use_custom_declaration_completeness_txt) {
        this.currentData.use_custom_declaration_completeness_txt = true;
        this.currentData.declaration_completeness = this.getCurrentUser().settings.declaration_completeness;
      } else {
        this.currentData.declaration_completeness = this.$t('settings.account.declaration_completeness.pdf_text')
      }

      if (this.getCurrentUser().settings.use_access_to_the_client_portal_txt) {
        this.currentData.use_access_to_the_client_portal_txt = true;
        this.currentData.access_to_the_client_portal = this.formatTemplate(this.getCurrentUser().settings.access_to_the_client_portal);
      } else {
        this.$api.getEmailTemplate('client_user_welcome').then((content) => {
          if (content) {
            this.currentData.access_to_the_client_portal = content
                .replace(/\n/g, "")
                .replace(/<br\s*[/]?>/gi, "\n");
          }
        })
      }

      if (this.getCurrentUser().settings.use_prompt_clients_to_create_plots_of_land_txt) {
        this.currentData.use_prompt_clients_to_create_plots_of_land_txt = true;
        this.currentData.prompt_clients_to_create_plots_of_land = this.formatTemplate(this.getCurrentUser().settings.prompt_clients_to_create_plots_of_land);
      } else {
        this.$api.getEmailTemplate('client_user_invitation').then((content) => {
          if (content) {
            this.currentData.prompt_clients_to_create_plots_of_land = content
                .replace(/\n/g, "")
                .replace(/<br\s*[/]?>/gi, "\n");
          }
        })
      }

      if (this.getCurrentUser().settings.use_release_by_email) {
        this.currentData.use_release_by_email = true;
        this.currentData.release_by_email = this.formatTemplate(this.getCurrentUser().settings.release_by_email);
      } else {
        this.$api.getEmailTemplate('property_declaration_multi_approval').then((content) => {
          if (content) {
            this.currentData.release_by_email = content
                .replace(/\n/g, "")
                .replace(/<br\s*[\/]?>/gi, "\n");
          }
        })
      }

      if (this.getCurrentUser().settings.use_send_document) {
        this.currentData.use_send_document = true;
        this.currentData.send_document = this.formatTemplate(this.getCurrentUser().settings.send_document);
      } else {
        this.$api.getEmailTemplate('send_document').then((content) => {
          if (content) {
            this.currentData.send_document = content
                .replace(/\n/g, "")
                .replace(/<br\s*[\/]?>/gi, "\n");
          }
        })
      }

      if (this.currentData.send_document === '') {
        this.currentData.send_document = this.$t('settings.taxCategories.send_document_msg_tmp');
      }

      if (this.getCurrentUser().settings.use_query_clients_to_change_property) {
        this.currentData.use_query_clients_to_change_property = true;
        this.currentData.query_clients_to_change_property = this.formatTemplate(this.getCurrentUser().settings.query_clients_to_change_property);
      } else {
        this.$api.getEmailTemplate('query_clients_to_change_property').then((content) => {
          if (content) {
            this.currentData.query_clients_to_change_property = content
                .replace(/\n/g, '')
                .replace(/<br\s*[\/]?>/gi, "\n");
          }
        })
      }

      if (this.currentData.query_clients_to_change_property === '') {
        this.currentData.query_clients_to_change_property = this.$t('settings.taxCategories.query_clients_to_change_property_text');
      }

      if (this.isAccountReorganisationAllowed) {
        this.tabsTC.push({
          name: this.$t('settings.taxCategories.reorg.title'),
          slug: 'reorg',
          noFooter: true
        });
      }

    } else {
      this.currentData = {
        data_acquisition: 0,
        data_acquisition_limit: 0,
        data_acquisition_limit_amount: 0,
        data_acquisition_month_total_items: 0,
        data_acquisition_month_total_price: 0,
        declaration_completeness: this.$t('settings.account.declaration_completeness.pdf_text'),
        use_custom_declaration_completeness_txt: false,
        elster_message_appeal_subject: '',
        use_elster_message_appeal_subject: false,
        elster_message_appeal_content: '',
        use_elster_message_appeal_content: false,
      };

      if (this.getCurrentUser().settings.use_custom_declaration_completeness_txt) {
        this.currentData.use_custom_declaration_completeness_txt = true;
        this.currentData.declaration_completeness = this.getCurrentUser().settings.declaration_completeness;
      } else {
        this.currentData.declaration_completeness = this.$t('settings.account.declaration_completeness.pdf_text')
      }
    }

    if (this.getCurrentUser().settings.use_elster_message_appeal_subject) {
      this.currentData.use_elster_message_appeal_subject = true;
      this.currentData.elster_message_appeal_subject = this.formatTemplate(this.getCurrentUser().settings.elster_message_appeal_subject);
    }

    if (this.currentData.elster_message_appeal_subject === '') {
      this.currentData.elster_message_appeal_subject = this.$t('settings.taxCategories.elster_message_appeal_subject_text');
    }

    if (this.getCurrentUser().settings.use_elster_message_appeal_content) {
      this.currentData.use_elster_message_appeal_content = true;
      this.currentData.elster_message_appeal_content = this.formatTemplate(this.getCurrentUser().settings.elster_message_appeal_content);
    }

    if (this.currentData.elster_message_appeal_content === '') {
      this.currentData.elster_message_appeal_content = this.$t('settings.taxCategories.elster_message_appeal_content_text');
    }

    this.currentData.partner = this.getCurrentUser().partner;
    this.currentData.data_acquisition = this.getCurrentUser().settings.data_acquisition ? 1 : 0;
    this.currentData.data_acquisition_limit = this.getCurrentUser().settings.data_acquisition_limit ? 1 : 0;
    this.currentData.data_acquisition_limit_amount = this.getCurrentUser().settings.data_acquisition_limit_amount ? this.getCurrentUser().settings.data_acquisition_limit_amount : 500;

    this.userService.getDataAcquisitionData().then((response) => {
      if (response === []) {
        return false;
      }

      this.currentData.data_acquisition_month_total_items = response.total_parcels;
      this.currentData.data_acquisition_month_total_price = response.total_costs
    });

    if (this.currentData.data_acquisition) {
      this.isDataAacquisitionChecked = 1;
    }

    if (this.currentData.data_acquisition_limit > 0) {
      this.dataAacquisitionLimitAmount = true;
    }

    this.currentData.force_2fa = this.getCurrentUser().settings.force_2fa ? 1 : 0;
    this.currentData.auto_generate_client_numbers = this.getCurrentUser().settings.auto_generate_client_numbers ? 1 : 0;
    this.currentData.disable_property_editing_after_internal_release = this.getCurrentUser().settings.disable_property_editing_after_internal_release ? 1 : 0;
    this.currentData.auto_client_permission = this.getCurrentUser().settings.auto_client_permission ? 1 : 0;
    this.currentData.allow_partner_connect = this.getCurrentUser().settings.allow_partner_connect ? 1 : 0;
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    formatTemplate(str) {
      var regex = /<br\s*[\/]?>/gi;
      return str.replace(regex, "");
    },
    handlePickerOneClick() {
      this.$refs.pickerOne.$el.click();
    },
    handlePickerTwoClick() {
      this.$refs.pickerTwo.$el.click();
    },
    handleClick(args) {
      if (args === 'cancel') {
        this.$router.push("/home")
      }
    },
    checkEmpty() {
      if (this.currentData.data_acquisition_limit === '') {
        this.currentData.data_acquisition_limit = 0;
      }
    },
    createOptionSelected(e) {
      this.radio = e
    },
    disconnectPartner() {
      this.$api.trigger('user/disconnect_partner', this.currentData, true).then((response) => {
        if (response.data.success) {
          this.$api.get_user();
          this.currentData.partner = '';
        } else {
          this.$api.showToast(this.$t('settings.taxSetting.error'), "error");
        }
      })
    },
    saveSetting() {
      this.invalidDataAcquisitionLimitAmount = false;
      if (this.isDataAacquisitionChecked === 1) {
        if (this.parseFloatLocalized(this.currentData.data_acquisition_limit_amount) < 2.5) {
          this.invalidDataAcquisitionLimitAmount = true;
          return true;
        }
      }

      if (this.getCurrentUser().settings.tax_office_software !== 'datev' && this.currentData.tax_office_software === 'datev') {
        this.$api.showToast(this.$t('settings.system.software_changed_to_datev'), "warn");
      } else if (this.getCurrentUser().settings.tax_office_software === 'datev' && this.currentData.tax_office_software !== 'datev') {
        this.$api.showToast(this.$t('settings.system.software_changed_from_datev'), "warn");
      }

      this.$api.trigger('setting/save', this.currentData, true).then((response) => {
        if (response.data.success) {
          if (parseInt(this.currentData.data_acquisition_limit_amount) === 0) {
            this.currentData.data_acquisition_limit_amount = 500;
          }
          this.$api.get_user();
          this.$toast.success(this.$t('settings.taxSetting.success'));
          this.showDataAcquisitionSystemStatusBySave();
        } else {
          this.$toast.error(this.$t('settings.taxSetting.error'));
        }
      })
    },
    showDataAcquisitionSystemStatusBySave() {
      if (Boolean(this.currentData.data_acquisition_limit) === true) {
        const amountAsNumber = parseFloat(this.currentData.data_acquisition_limit_amount.replace(',', '.'));
        const amountAsCurrency = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(amountAsNumber);
        this.$toast.success(this.$t('settings.taxCategories.cost_limit_bubble').replace('%s', amountAsCurrency));
      }
    },
    nextClick() {
      switch (this.radio) {
        case "alle":
          this.alle = false;
          this.showRadio = false;
          this.alleStep = true
          break;
        case "mandanten":
          this.mandanten = true;
          this.showRadio = false;

          break;
        case "manual":
          this.$router.push('/client/manual')
          break;
      }
    },
    handleModalClose() {
      this.isProcessing = true;
      this.synckDialog = false;
    },
    handleSettingModalClose() {
      this.settingDialog = false;
      this.alle = true;
      this.mandanten = false;
      this.showRadio = true;
      this.alleStep = false;
    },
    openSettingDialog() {
      this.settingDialog = true
    },
    synck() {
      this.isProcessing = true;
      this.synckDialog = true;
      setTimeout(() => {
        this.isProcessing = false
        setTimeout(() => {
          this.synckDialog = false
        }, 500)

      }, 1500)

    },
    deletePic() {
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }

      this.$api.trigger('user/delete_photo', {
        "photoType": "company"
      }, true).then((response) => {
        if (response.data.success) {
          this.$api.get_user();
          this.currentUser.companyLogoPic = '';
        }
      })
    },
    chooseCompanyLogo() {
      this.$refs.fileInputCompany.click()
    },
    photoSelect(event) {
      this.isCropModalVisible = true
      const files = event.target.files
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }
      const blob = URL.createObjectURL(files[0]);
      this.image = {
        name: files[0].name,
        src: blob,
        type: files[0].type,
      };
      // Clear input
      this.$refs.fileInputCompany.value = '';
    },
    cancelCrop() {
      this.isCropModalVisible = false;
    },
    cropImage() {
      let croppedImg = this.$refs.cropper.getResult().canvas.toDataURL(this.image.type);
      this.$api.trigger('user/upload_photo', {
        "name": this.image.name,
        "croppedImg": croppedImg,
        "photoType": "company"
      }, true).then((response) => {
        if (response.data.success) {
          if (this.image.src) {
            URL.revokeObjectURL(this.image.src);
          }
          this.$api.get_user();
          this.currentUser.companyLogoPic = response.data.image_url;
          this.isCropModalVisible = false;
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      });
    },
    selectedUseBranchData(checked) {
      this.currentData.use_branch_data = checked ? 1 : 0;
    },
    toggleDeclarationCompleteness(checked) {
      this.currentData.use_custom_declaration_completeness_txt = checked;
      if (!checked) {
        if (this.getCurrentUser().settings.use_custom_declaration_completeness_txt) {
          this.currentData.declaration_completeness = this.getCurrentUser().settings.declaration_completeness;
        } else {
          this.currentData.declaration_completeness = this.$t('settings.account.declaration_completeness.pdf_text');
        }
      }
    },
    toggleAccessToClientPortal(checked) {
      this.currentData.use_access_to_the_client_portal_txt = !!checked;
      if (!checked) {
        if (this.getCurrentUser().settings.use_access_to_the_client_portal_txt) {
          this.currentData.access_to_the_client_portal = this.formatTemplate(this.getCurrentUser().settings.access_to_the_client_portal);
        } else {
          this.currentData.access_to_the_client_portal = this.$t('settings.taxCategories.access_to_the_client_portal_text');
        }
      }
    },
    togglePromptClientsToCreatePlotsOfLand(checked) {
      this.currentData.use_prompt_clients_to_create_plots_of_land_txt = !!checked;
      if (!checked) {
        if (this.getCurrentUser().settings.use_prompt_clients_to_create_plots_of_land_txt) {
          this.currentData.prompt_clients_to_create_plots_of_land = this.formatTemplate(this.getCurrentUser().settings.prompt_clients_to_create_plots_of_land);
        } else {
          this.currentData.prompt_clients_to_create_plots_of_land = this.$t('settings.taxCategories.prompt_clients_to_create_plots_of_land_text');
        }
      }
    },
    toggleReleaseByEmail(checked) {
      this.currentData.use_release_by_email = !!checked;
      if (!checked) {
        if (this.getCurrentUser().settings.use_release_by_email) {
          this.currentData.release_by_email = this.formatTemplate(this.getCurrentUser().settings.release_by_email);
        } else {
          this.currentData.release_by_email = this.$t('settings.taxCategories.prompt_clients_to_create_plots_of_land_text');
        }
      }
    },
    toggleSendDocument(checked) {
      this.currentData.use_send_document = !!checked;
      if (!checked) {
        if (this.getCurrentUser().settings.use_send_document) {
          this.currentData.send_document = this.formatTemplate(this.getCurrentUser().settings.send_document);
        } else {
          this.currentData.send_document = this.$t('settings.taxCategories.send_document_msg_tmp');
        }
      }
    },
    toggleElsterMessageAppealSubject(checked) {
      this.currentData.use_elster_message_appeal_subject = !!checked;
      if (!checked) {
        if (this.getCurrentUser().settings.use_elster_message_appeal_subject) {
          this.currentData.elster_message_appeal_subject = this.formatTemplate(this.getCurrentUser().settings.elster_message_appeal_subject);
        }
      }

      if (this.currentData.elster_message_appeal_subject === '') {
        this.currentData.elster_message_appeal_subject = this.$t('settings.taxCategories.elster_message_appeal_subject_text');
      }
    },
    toggleElsterMessageAppealContent(checked) {
      this.currentData.use_elster_message_appeal_content = !!checked;
      if (!checked) {
        if (this.getCurrentUser().settings.use_elster_message_appeal_content) {
          this.currentData.elster_message_appeal_content = this.formatTemplate(this.getCurrentUser().settings.elster_message_appeal_content);
        }
      }

      if (this.currentData.elster_message_appeal_content === '') {
        this.currentData.elster_message_appeal_content = this.$t('settings.taxCategories.elster_message_appeal_content_text');
      }
    },
    toggleQueryClientsToChangeProperty(checked) {
      this.currentData.use_query_clients_to_change_property = !!checked;
      if (!checked) {
        if (this.getCurrentUser().settings.use_query_clients_to_change_property) {
          this.currentData.query_clients_to_change_property = this.formatTemplate(this.getCurrentUser().settings.query_clients_to_change_property);
        } else {
          this.currentData.query_clients_to_change_property = this.$t('settings.taxCategories.query_clients_to_change_property_text');
        }
      }
    },
    datevChecked(checked) {
      this.currentData.only_datev_client_import = checked ? 1 : 0;
    },
    dmsChecked(checked) {
      this.currentData.datev_dms_enabled = checked ? 1 : 0;
    },
    datevGgwChecked(checked) {
      this.currentData.datev_ggw_enabled = checked ? 1 : 0;
    },
    addisonGgwChecked(checked) {
      this.currentData.addison_ggw_enabled = checked ? 1 : 0;
    },
    force2faChecked(checked) {
      if (!this.$isBusiness && this.currentData.tax_office_software === 'datev') {
        this.currentData.force_2fa = 0
      } else {
        this.currentData.force_2fa = checked ? 1 : 0;
      }
    },
    propertyEditingChecked(checked) {
      this.currentData.disable_property_editing_after_internal_release = checked ? 1 : 0;
    },
    autoGenerateClientNumberChecked(checked) {
      this.currentData.auto_generate_client_numbers = checked ? 1 : 0;
    },
    autoClientPermissionChecked(checked) {
      this.currentData.auto_client_permission = checked ? 1 : 0;
    },
    allowPartnerConnectChecked(checked) {
      this.currentData.allow_partner_connect = checked ? 1 : 0;
    },
    // Data Aacquisition events
    dataAacquisitionChecked(checked) {
      this.currentData.data_acquisition = checked ? 1 : 0;
      this.isDataAacquisitionChecked = this.currentData.data_acquisition;
      this.dataAacquisitionDialog = !!checked;
      this.dataAacquisitionLimit = this.currentData.data_acquisition !== 1;
      if (this.isDataAacquisitionChecked === 0) {
        this.currentData.data_acquisition_limit = 0;
      }
    },
    handleModaldataAacquisition() {
      this.dataAacquisitionDialog = false;
    },
    confirmDataAacquisition(value) {
      if (value === false) {
        this.currentData.data_acquisition = 0;
        this.currentData.data_acquisition_limit = 0;
      }
      this.dataAacquisitionDialog = false;
    },
    // Data Aacquisition Client Portal events
    dataAacquisitionClientPortalChecked(checked) {
      this.currentData.data_acquisition_client_portal = checked ? 1 : 0;
      this.dataAacquisitionClientPortalDialog = !!checked;
    },
    handleModaldataAacquisitionClientPortal() {
      this.dataAacquisitionClientPortalDialog = false;
    },
    confirmDataAacquisitionClientPortal(value) {
      if (value === false) {
        this.currentData.data_acquisition_client_portal = 0;
      }
      this.dataAacquisitionClientPortalDialog = false;
    },
    // Data Aacquisition Limit events
    dataAacquisitionLimitChecked(checked) {
      this.currentData.data_acquisition_limit = checked ? 1 : 0;
      this.dataAacquisitionLimitDialog = !!checked;
      this.dataAacquisitionLimitAmount = !!checked;
    },
    handleModaldataAacquisitionLimit() {
      this.dataAacquisitionLimitDialog = false;
    },
    confirmDataAacquisitionLimit(value) {
      if (value === false) {
        this.currentData.data_acquisition_limit = 0;
        this.dataAacquisitionLimitAmount = false;
      } else {
        this.dataAacquisitionLimitAmount = true;
      }
      this.dataAacquisitionLimitDialog = false;
    },
    handleDatevLogout() {
      this.logout(true);
    }
  }
}
</script>

<style scoped lang="scss">


.max_420 label {
  text-align: left !important;
}

.height_auto {
  padding: 20px !important;
  height: auto !important;
  max-width: 70%;
  width: 100%;
}

.button {

  height: 32px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.line {
  width: 97px;
  //height: .1px;
  padding: 2px 2px 2px 2px;
  //border: 1px solid #333333;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
}


.tied {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: #54e59b;
  text-align: left;
  line-height: 15px;
  font-size: 13px
}

.p-header {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: normal;
}

.setting-rectangle {
  padding: 15px;
  border: 1px solid #dfdfdf;
  background-color: rgba(255, 255, 255, 0);
}

.help_icon {

  margin-left: 5px;
  width: 18px;
  height: 18px;

}

.updated {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;

  &__normal {
    font-family: 'Segoe UI', 'Segoe UI Regular', 'Segoe UI', sans-serif;
    font-weight: normal;
  }
}

.dheader {
  position: relative;
  top: -35px;
  left: -20px;
  max-width: 50%;

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

.loader {
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;

  line-height: 20px;
  font-size: 15px;
  margin-bottom: 50px;
}

.text1 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  line-height: 20px;
  font-size: 15px;
  text-align: left;
}

input[type=radio] {
  border: 0;
  width: 2em;
  height: 25px;
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.box {
  padding: 15px 5px 15px 5px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.box2 {
  height: 60px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.image-box {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.text-box {
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 15px;
}

.label1 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.eyeIcon {
  position: absolute;
  right: 12%;
  margin-top: 35px;
}

.submit-btn {
  margin-bottom: 8px !important;
}

.latest {
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 50px;
  font-size: 15px;
}

.column_icon {
  margin-right: 5px;
  width: 24px;
  height: 24px;
  color: #333333;
}

.link {
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: $primary;
  text-align: left;
  line-height: normal;
  font-size: 15px;
  cursor: pointer;
}

.color-wrapper {
  width: 96px;
  height: 32px;
  padding: 2px 2px 2px 2px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
}

.arrow {
  width: 10px;
  height: 6px;
  box-sizing: border-box;
}

.button {
  min-width: 96px !important;
  @include segoe-regular;
  color: #ffffff;
  text-align: center;
}

.icp__input::-webkit-color-swatch {
  border: none;
  border-radius: 0;
}

.profile-pic {
  width: 112px;
  height: 111px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  margin-top: 5px;
  border-radius: 50% 50%;
  object-fit: cover;

  &.selected {
    max-width: 100%;
    height: auto;
    border-radius: 0;
    width: auto;
    max-height: 100px;
  }
}

.date_format {
  font-family: 'Segoe UI', sans-serif;

  color: #a1a1a1;
  text-align: left;
  line-height: normal;
  font-size: 12px;
  width: 30%;
}

.grey-bg {
  background-color: #eeeeee;
}

.box-client-portal {
  border: 1px solid #dfdfdf;
  padding: 20px;

  .text {
    margin-bottom: 0;
  }
}

.info-msg {
  background-color: #f2f2f2;

  label {
    font-size: 14px;
    color: theme('colors.muted_black');
    line-height: 20px;
  }

  .border-left {
    border-left: 1px solid #dbdbdb;
  }
}

.info {
  padding: 5px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 20px;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px
  }
}

</style>
