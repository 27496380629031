import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '../store'
import {JSEncrypt} from 'jsencrypt'
import {i18n} from "@/plugins/i18n";

export class ApiService {

  constructor() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.headers.post['Content-Type'] = 'application/json';
    Vue.axios.defaults.headers.post['X-LANG'] = i18n.locale;
    Vue.axios.defaults.baseURL = '/api/';
    this.setCSRFHeader();

    this.defaultParams = {
      '_product_type': process.env.VUE_APP_DEV_VARIANT ? process.env.VUE_APP_DEV_VARIANT : 'tax_consultant'
    };

    this.$toast = Vue.prototype.$toast;
    this.$store = store;
    this.$i18n = i18n;
  }

  getCancelSource() {
    let cancelSource = Vue.axios.CancelToken.source();
    this.cancelToken = cancelSource.token;
    return cancelSource;
  }

  showToast(message, type) {
    if (message) {
      if (type && type === 'error') {
        this.$toast.error(message);
      } else if (type && type === 'warn') {
        this.$toast.warning(message);
      } else if (type && type === 'info') {
        this.$toast.info(message);
      } else {
        this.$toast.success(message);
      }
    }
  }

  setCSRFHeader() {
    // CSRF Protection
    if (process.env.VUE_APP_ENABLE_CSRF === 'true') {
      let cookie_val = `; ${document.cookie}`;
      let cookie_parts = cookie_val.split('; GRST-CSRF=');
      if (cookie_parts.length === 2) {
        Vue.axios.defaults.headers.post['X-GRST-CSRF'] = cookie_parts.pop().split(';').shift();
      } else {
        Vue.axios.defaults.headers.post['X-GRST-CSRF'] = null;
      }
    }
  }

  setDefaultParams(params) {
    if (params && Object.keys(params).length > 0) {
      this.defaultParams = {...this.defaultParams, ...params};
    }
  }

  trigger(endpoint, data, show_loader, content_type, response_type, handle_timeout = false) {
    this.setCSRFHeader();

    let config = {
      "headers": {}
    };
    if (typeof response_type !== 'undefined' && response_type !== '') {
      config.responseType = response_type;
      if (response_type === 'blob' && data) {
        if (typeof data.export_filters !== 'undefined') {
          if (typeof data.export_filters.txt != 'undefined') {
            data.export_filters.txt = encodeURIComponent(data.export_filters.txt);
          }

          if (typeof data.export_filters.txt_filter != 'undefined') {
            data.export_filters.txt_filter = encodeURIComponent(data.export_filters.txt_filter);
          }
        }

        config.headers['x-payload'] = JSON.stringify(data);
      }
    }

    if (this.cancelToken) {
      config.cancelToken = this.cancelToken;
      this.cancelToken = null;
    }

    if (typeof (content_type) !== 'undefined' && content_type !== '') {
      Vue.axios.defaults.headers.post['Content-Type'] = content_type;
      if (content_type === 'multipart/form-data') {
        if (this.defaultParams && Object.keys(this.defaultParams).length > 0) {
          for (let key in this.defaultParams) {
            data.append(key, this.defaultParams[key])
          }
        }
      }
    } else {
      Vue.axios.defaults.headers.post['Content-Type'] = 'application/json';
      if (this.defaultParams && Object.keys(this.defaultParams).length > 0) {
        data = {...data, ...this.defaultParams};
      }
      data = JSON.stringify(data);
    }

    if (typeof (show_loader) === 'undefined') {
      show_loader = false;
    }
    if (show_loader) {
      Vue.prototype.$loading.show();
    }

    return Vue.axios.post(endpoint, data, config).then((response) => {
      if (show_loader) {
        Vue.prototype.$loading.hide();
      }

      if (!this.sessionExpired && response.data.error_key && response.data.error_key === 'sessionExpired' && endpoint !== 'get_current_user') {
        this.sessionExpired = true;
        localStorage.removeItem('tc_logged_in');
        if (show_loader) {
          Vue.prototype.$loading.hide();
        }

        let fusionRole = this.$store.getters["user/getFusionRole"] ?? '';
        Vue.prototype.$modal.show({
          headerText: fusionRole
            ? this.$i18n.t('settings.taxCategories.reorg.fusion_workflow.expired_title')
            : this.$i18n.t('general.errors.error'),
          text: fusionRole
            ? this.$i18n.t('settings.taxCategories.reorg.fusion_workflow.expired_message')
            : this.$i18n.t('general.errors.session_expired'),
          showCancel: false,
          showClose: false,
          canEscape: false,
          buttons: {
            ok: this.$i18n.t('general.ok')
          },
          onConfirm: () => {
            localStorage.removeItem('tc_logged_in');
            this.trigger('logout', null).then(() => {
              if (localStorage.getItem('is_new_ui_user') === '1') {
                window.location.replace(process.env.VUE_APP_UI_V2_URL);
              } else {
                location.href = '/';
              }
            });
          }
        });
      }

      return response;
    }).catch((e) => {
      if (e.response) {
        if (e.response.status === 403 && typeof (e.response.data.error_key) !== 'undefined' && e.response.data.error_key === 'csrfInvalid') {
          if (show_loader) {
            Vue.prototype.$loading.hide();
          }

          if (!this.csrfFailed) {
            this.csrfFailed = true;
            Vue.prototype.$modal.show({
              headerText: this.$i18n.t('general.errors.error'),
              text: this.$i18n.t('general.errors.csrf_error'),
              showCancel: false,
              showClose: false,
              canEscape: false,
              buttons: {
                ok: this.$i18n.t('general.ok')
              },
              onConfirm: () => {
                location.reload();
              }
            });
          }

          return e.response;
        }

        if (handle_timeout && [504, 524].includes(e.response.status)) {
          if (show_loader) {
            Vue.prototype.$loading.hide();
          }
          return e.response;
        }

        if (show_loader) {
          Vue.prototype.$loading.hide();
          this.showToast(this.$i18n.t('general.errors.unknownError'), 'error')
        }

        return e.response;
      }
    });
  }

  encrypt(text) {
    if (process.env.VUE_APP_ENABLE_UI_ENC === "true") {
      let ui_enc_token = '';
      let cookie_val = `; ${document.cookie}`;
      let cookie_parts = cookie_val.split('; tc_ui_key=');
      if (cookie_parts.length === 2) {
        ui_enc_token = decodeURIComponent(cookie_parts.pop().split(';').shift());
      }

      if (ui_enc_token) {
        const encryptor = new JSEncrypt({});

        encryptor.setPublicKey(atob(ui_enc_token));

        return encryptor.encrypt(text);
      }
    }
    return text
  }

  parse_object_data(data) {
    const parsed_data = [];
    const data_length = Object.keys(data).length;
    if (data_length > 0) {
      for (let i = 0; i < data_length; i++) {
        parsed_data.push(data[i])
      }
    }

    return parsed_data;
  }

  get_country_list() {
    if (this.$store.getters["countries/getCountries"].length > 0) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["countries/getCountries"]);
      });
    } else {
      return this.trigger('get_country_list', null).then((response) => {
        if (response.data.success) {
          let list = this.parse_object_data(response.data.countries);
          this.$store.commit("countries/setCountries", list);

          return list;
        } else {
          return [];
        }
      });
    }
  }

  get_state_list() {
    if (this.$store.getters["federal_states/getFederalStates"].length > 0) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["federal_states/getFederalStates"]);
      });
    } else {
      return this.trigger('get_federal_states', null).then((response) => {
        if (response.data.success) {
          let list = this.parse_object_data(response.data.states);
          this.$store.commit("federal_states/setFederalStates", list);

          return list;
        } else {
          return [];
        }
      });
    }
  }

  get_state_holidays() {
    if (this.$store.getters["federal_states/getStateHolidays"].length > 0) {
      return new Promise((resolve) => {
        resolve(this.$store.getters["federal_states/getStateHolidays"]);
      });
    } else {
      return this.trigger('get_state_holidays', null).then((response) => {
        if (response.data.success) {
          let holidays = response.data.holidays;
          this.$store.commit("federal_states/setStateHolidays", holidays);

          return holidays;
        } else {
          return [];
        }
      });
    }
  }

  get_community_list(federal_state) {
    let cachedList = this.$store.getters["federal_states/getCommunities"];
    if (cachedList[federal_state]) {
      return new Promise((resolve) => {
        resolve(cachedList[federal_state]);
      });
    } else {
      return this.trigger('get_community_list', {"state": federal_state}).then((response) => {
        if (response.data.success) {
          let list = this.parse_object_data(response.data.communities);
          this.$store.commit("federal_states/setCommunities", {
            "stateId": federal_state,
            "communities": list
          })

          return list;
        } else {
          return [];
        }
      });
    }
  }

  get_gemarkungen_list(state, community) {
    let stateId = state + '-' + community;
    return this.trigger('get_gemarkungen_list', {"state": state, "community": community}).then((response) => {
      if (response.data.success) {
        return this.parse_object_data(response.data.parcels);
      } else {
        return [];
      }
    });
  }

  get_area_of_the_land_value(address) {
    return this.trigger('get_area_of_the_land_value', {"address": address}).then((response) => {
      return response.data;
    });
  }

  lookup_city(zip) {
    return this.trigger('get_city', {"zip": zip}).then((response) => {
      if (response.data.success) {
        return this.parse_object_data(response.data.cities);
      } else {
        return [];
      }
    });
  }

  get_user(show_loader) {
    if (typeof show_loader === 'undefined') {
      show_loader = false;
    }
    return this.trigger('get_current_user', null, show_loader).then((response) => {
      if (typeof response !== 'undefined' && response.data.success) {
        this.$store.commit('user/setCurrentUser', response.data.userData)
        if (response.data.newNotificationCount) {
          this.$store.commit('user/setNewNotificationCount', response.data.newNotificationCount)
        }
        if (response.data.notificationCount) {
          this.$store.commit('user/setNotificationCount', response.data.notificationCount)
        }
      }
      return response;
    });
  }

  send_header(key, val) {
    if (key !== '' && val !== '') {
      Vue.axios.defaults.headers.post['X-' + key.toUpperCase()] = val;
    }
  }

  trigger_download(response) {
    if (response.headers['filename']) {
      let filename = response.headers['filename'];
      if (!window.navigator.msSaveOrOpenBlob) {
        // BLOB NAVIGATOR
        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        if (response['showSuccessMsg']) {
          setTimeout(
            () => this.showToast(
              this.$i18n.t(
                'settings.taxCategories.reorg.fusion_workflow.manage_documents.download_success'),
              'success'
            ),
            800);
        }
      } else {
        // BLOB FOR EXPLORER 11
        window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename);
      }
    } else {
      const promise = response.data.text();
      const promise_error_key = promise.then((data) => JSON.parse(data))
          .then((data_ar) => {
            return data_ar.error_key;
          });
      const testErrorKey = () => {
        promise_error_key.then((error_key) => {
          if (error_key == 'exportJobPending' ) {
            this.showToast(this.$i18n.t('general.errors.carriedOutExport'), "error");
          } else if (error_key === 'unknownDownloadError') {
            this.showToast(this.$i18n.t('general.errors.unknownDownloadError'), "error");
          } else {
            this.showToast(this.$i18n.t('general.errors.noExportData'), "error");
          }
        });
      };
      testErrorKey();
    }
  }

  getEmailTemplate(template_key, show_loader) {
    if (typeof show_loader === 'undefined') {
      show_loader = false;
    }
    return this.trigger('email/get', {
      'template_key': template_key
    }, show_loader).then((response) => {
      if (response.data.success) {
        let payload = {};
        payload[template_key] = response.data.content;
        this.$store.commit('email/saveEmailTemplate', payload)
        return response.data.content;
      } else {
        return '';
      }
    });
  }

  async getDatevLocalParams(show_loader) {
    if (process.env.VUE_APP_DATEV_LOCALHOST) {
      if (show_loader) {
        Vue.prototype.$loading.show();
      }

      return await Vue.axios.get(process.env.VUE_APP_DATEV_LOCALHOST, {'timeout': 2500}).then((response) => {
        if (response.data.idpOnPremiseUri) {
          // looks like there is a datev client running, request authorization token
          return Vue.axios.get(response.data.idpOnPremiseUri + '/AuthorizationRequestGenerator', {'timeout': 2500}).then((response) => {
            if (response.data.request) {
              // detected local DATEV com server
              return {
                'request': response.data.request,
                'CASCADE_DESTINATION_MEDIUM': 'DATEV_commserver'
              }
            } else {
              return {};
            }
          }).catch(() => {
            return {};
          });
        } else {
          return {};
        }
      }).catch(() => {
        return {};
      });
    } else {
      return {};
    }
  }

  get_datev_org_info() {
    return this.trigger('datev/org_info', null).then((response) => {
      if (response.data.success) {
        if (response.data.org_data && response.data.org_data.business_partners) {
          response.data.org_data.business_partners = this.parse_object_data(response.data.org_data.business_partners);
        }
        return response.data;
      } else {
        this.showToast(response.data.message, "error")
        return [];
      }
    });
  }

  get_datev_data_environments() {
    return this.trigger('datev/data_environments', null).then((response) => {
      if (response.data.success) {
        if (response.data.environments) {
          response.data.environments = this.parse_object_data(response.data.environments);
        }
        return response.data;
      } else if (typeof(response.data.error_key) != 'undefined') {
        if (response.data.error_key === 'emptyData') {
          this.showToast(this.$i18n.t('settings.branches.datev_import.no_branches_error'), 'error');
        } else {
          this.showToast(response.data.message, 'error');
        }
        return [];
      } else {
        this.showToast(response.data.message, 'error');
        return [];
      }
    });
  }
}
